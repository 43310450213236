<template>
  <div class="class">
    <div class="class-content">
      <div class="class-content-left">
        <div class="class-left-head">
          <div class="class-left-head-pad" @click="bindClassAll()">
            <span>全部班级</span>
            <span
              class="bindClassAll"
              @click.stop="bindAddClass"
              v-if="!$store.state.userInfo.teacherId"
            >
              新建班级
            </span>
          </div>
        </div>
        <div class="class-left-list">
          <div class="menu-item-1">
            <!-- <div
              class="menu-item-title"
              :class="banType === 'all' ? 'active' : ''"
              @click="bindClassAll()"
            >
              <span>全部班级</span>
            </div> -->
            <div
              class="menu-item-1-1"
              v-for="(item, index) in menuData"
              :key="index"
            >
              <div
                class="menu-item-1-title"
                @click="bindMenu(index)"
                :class="isModel === index ? 'activeSan' : ''"
              >
                <span>{{ item.name }}</span>
              </div>
              <div class="menu-item-1-2" v-if="isModel === index">
                <div
                  class="menu-item-1-2-con"
                  v-for="(items, indexI) in item.list"
                  :key="indexI"
                  @click="getBanID(items.id, item.type, items.name, items)"
                  :class="gradeId === items.id ? 'active' : ''"
                >
                  <p :class="items.open == 0 ? 'openActive' : ''">
                    {{ items.name }}<span v-if="items.open == 0">(已禁用)</span>
                  </p>
                  <div v-for="(itemss, indexss) in items.list" :key="indexss">
                    {{ itemss.name }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="menu-item-1-title menu-item-1-title-other"
              @click="bindMenus"
              :class="isJude === 'isHide' ? 'activeSan' : ''"
            >
              <span>其他</span>
            </div>
            <div class="menu-item-content" v-if="isJude === 'isHide'">
              <div
                class="menu-item-1-1"
                v-for="(item, key, index) in menuDatas"
                :key="index"
              >
                <div
                  class="menu-item-1-title"
                  @click="bindMenu(item.index)"
                  :class="isModel === item.index ? 'activeSan' : ''"
                >
                  <span>{{ item.name }}</span>
                </div>
                <div class="menu-item-1-2" v-if="isModel === item.index">
                  <div v-for="(items, key, indexs) in item.list" :key="indexs">
                    <span
                      class="menu-item-1-title-year"
                      @click="bindMenuThere(indexs)"
                      :class="isModelTherr === indexs ? 'activeSan' : ''"
                      >{{ key }}</span
                    >
                    <div class="therr" v-if="isModelTherr === indexs">
                      <div
                        v-for="(val, index) in items"
                        :key="index"
                        class="menu-item-1-2-con"
                        @click="getBanID(val.id, item.type, val.name)"
                        :class="gradeId === val.id ? 'active' : ''"
                      >
                        {{ val.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="menu-list">
                <div
                  class="menu-item-add"
                  @click="getBanID('backlog', 'backlog', '待入园')"
                  :class="banType === 'backlog' ? 'active' : ''"
                  v-if="!$store.state.userInfo.teacherId"
                >
                  待入园
                </div>
                <div
                  class="menu-item-add"
                  @click="
                    getBanID('notPerfect', 'notPerfect', '已配课未完善信息')
                  "
                  :class="banType === 'notPerfect' ? 'active' : ''"
                >
                  已配课未完善信息
                </div>
                <div
                  class="menu-item-add"
                  @click="getBanID('delList', 'delList', '移除列表')"
                  :class="banType === 'delList' ? 'active' : ''"
                >
                  移除列表
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="class-content-right">
        <!-- <div class="class-right-head-input-fen">
          <div class="input-fen-right">
            <input
              type="text"
              placeholder="请输入要查询的内容"
              v-model="seachVal"
            />
            <input
              style="display: block; width: 0px; height: 0px; opacity: 0"
              placeholder=""
              resize="none"
            />
            <span @click="bindSeach">查询</span>
          </div>
          <div
            class="class_go_up_btn"
            v-if="!$store.state.userInfo.teacherId"
            @click="promotion"
          >
            <img src="../../../assets/img/school/Icon_SB.png" alt="" />
            学年升班
          </div>
        </div> -->
        <classAllCof
          v-if="banType == 'all'"
          :banName="'全部班级'"
          :gradeId="String(banType)"
        ></classAllCof>
        <classBanCof
          v-if="banType == 'ban'"
          :gradeId="String(gradeId)"
          @bindUpClass="bindUpdateBan"
        ></classBanCof>
        <classGraCof
          v-if="banType == 'tograduate'"
          :gradeId="String(gradeId)"
          :banName="banName"
          @bindSUcc="bindUpdate"
        ></classGraCof>
        <classGraYCof
          v-if="banType == 'yetgraduate'"
          :banName="'已毕业'"
          :gradeId="String(gradeId)"
        ></classGraYCof>
        <classUndCof
          v-if="banType == 'backlog'"
          :banName="'待入园'"
          :gradeId="String(banType)"
        ></classUndCof>
        <classNotCof
          v-if="banType == 'notPerfect'"
          :banName="'已配课未完善信息'"
          :gradeId="String(banType)"
        ></classNotCof>
        <classDelCof
          v-if="banType == 'delList'"
          :banName="'移除列表'"
          :gradeId="String(banType)"
        ></classDelCof>
        <classSecCof
          v-if="banType == 'sec'"
          :banName="'全园搜索'"
          :gradeId="String(gradeId)"
        >
        </classSecCof>
      </div>
    </div>
    <!-- 增加班级 -->
    <comdialog
      :list="formListClassAdd"
      ref="comdialogClass"
      :params.sync="atteGradeList"
      :edit="alertStatusClass"
      :title="alertStatusClass"
      :isLoading="isLoading"
      @onSubmit="onSubmitClass"
      @close="comdialogCloseClass"
      @addClass="comdialogAddClass"
    ></comdialog>

    <riseDialog
      ref="riseDialog"
      @onSubmit="bindUPclass"
      @close="comdialogCloserise"
    ></riseDialog>
  </div>
</template>
<script>
import comdialog from "@/components/admin/batchAddDialog.vue";
//import classCof from "@/views/admin/school/campusAdmin/classCof.vue";
import classAllCof from "@/views/admin/school/campusAdmin/classAllCof.vue"; // 全部班级
import classBanCof from "@/views/admin/school/campusAdmin/classBanCof.vue"; // 大中小班
import classGraCof from "@/views/admin/school/campusAdmin/classGraCof.vue"; // 未毕业
import classGraYCof from "@/views/admin/school/campusAdmin/classGraYCof.vue"; // 已毕业
import classUndCof from "@/views/admin/school/campusAdmin/classUndCof.vue"; // 待入校
import classNotCof from "@/views/admin/school/campusAdmin/classNotCof.vue"; // 未完善
import classDelCof from "@/views/admin/school/campusAdmin/classDelCof.vue"; // 移除列表
import classSecCof from "@/views/admin/school/campusAdmin/classSecCof.vue"; // 移除列表
import riseDialog from "@/components/admin/riseDialog.vue";
export default {
  data() {
    return {
      isJude: "",
      banInfo: {}, // 班级信息
      seachVal: "",
      updateParams: {
        // 分页
        page: 0,
        size: 50,
        total: 0,
      },
      banName: "全部班级",
      menuData: [], //学校班级列表
      menuDatas: [], //学校班级列表
      activeName: "", // 学校默认高亮值
      isModel: "",
      isModelTherr: "",
      alertStatusClass: "",
      isLoading: false,
      gradeId: "all",
      banType: "all",
      tecData: "",
      atteGradeList: [
        {
          name: "",
          type: "",
          open: "",
          teacherIdList: [],
        },
      ],
      formListClassAdd: [
        {
          prop: "type",
          type: "select",
          label: "所带班级",
          multiple: false,
          rules: [
            { required: true, message: "请选择所带班级", trigger: "blur" },
          ],
          options: [
            {
              label: "小班",
              value: 2,
            },
            {
              label: "中班",
              value: 1,
            },
            {
              label: "大班",
              value: 0,
            },
          ],
        },
        {
          prop: "name",
          type: "text",
          label: "班级名称",
          multiple: true,
          rules: [
            {
              required: true,
              message: "请输入班级名称",
              trigger: "blur",
            },
          ],
        },
        {
          prop: "open",
          type: "select",
          label: "班级状态",
          multiple: false,
          rules: [
            { required: true, message: "请选择班级状态", trigger: "blur" },
          ],
          options: [
            {
              label: "开启",
              value: 1,
            },
            {
              label: "关闭",
              value: 0,
            },
          ],
        },
        {
          prop: "teacherIdList",
          type: "select",
          label: "关联老师",
          multiple: true,
          rules: [
            { required: true, message: "请选择关联老师", trigger: "blur" },
          ],
          options: [],
        },
      ],
    };
  },
  mounted() {
    this.getClassInfo(); // 获取默认菜单列表
    this.findSchoolInfo(); // 获取所有老师
  },
  methods: {
    // v2.0 学校升班
    promotion() {
      this.$refs.riseDialog.isOpen();
    },
    bindUPclass() {
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
      };
      this.api.school.promotion(data).then((res) => {
        if (res.code == 20000) {
          this.$notice({
            type: "success",
            message: res.message,
          }).isShow();
          this.$refs.riseDialog.isClose();
        } else {
          this.$notice({
            type: "warn",
            message: res.message,
          }).isShow();
          this.$refs.riseDialog.isClose();
        }
      });
    },
    comdialogCloserise() {
      this.$refs.riseDialog.isClose();
      console.log(111111111111111111111111111);
    },
    bindUpdate() {
      this.menuDatas = [];
      this.getClassInfo();
    },
    bindUpdateBan() {
      this.menuDatas = [];
      this.getClassInfo();
      this.banType = "all";
      this.isModel = "";
    },
    bindSeach() {
      this.isModel = "";
      this.banType = "sec";
      this.gradeId = this.seachVal;
    },
    bindClassAll() {
      this.isModel = "";
      this.banType = "all";
    },
    getBanID(id, type, name, val) {
      this.gradeId = id;
      this.banType = type;
      this.banName = name;
      this.banInfo = val;
    },
    // v2,0 菜单
    bindMenu(index) {
      if (this.isModel === index) {
        this.isModel = "";
      } else {
        this.isModel = index;
      }
    },
    bindMenuThere(indexs) {
      if (this.isModelTherr === indexs) {
        this.isModelTherr = "";
      } else {
        this.isModelTherr = indexs;
      }
    },
    bindMenus() {
      if (this.isJude === "isHide") {
        this.isJude = "";
      } else {
        this.isJude = "isHide";
      }
    },
    comdialogAddClass() {
      this.atteGradeList.push({
        name: "",
        type: "",
        open: "",
        teacherIdList: [],
      });
    },
    // V2.0 获取学校列表
    getClassInfo() {
      if (!this.$store.state.userInfo.teacherId) {
        this.menuData = [];
        this.api.school
          .getAllGradeLabel(this.$store.state.userInfo.schoolId)
          .then((res) => {
            console.log(res, "班级信息111111111");
            for (let val in res.data) {
              if (val == "小班" || val == "中班" || val == "大班") {
                let obj = {
                  name: val,
                  type: "ban",
                  list: res.data[val],
                };
                let idIndex = this.menuData.indexOf(obj);
                if (idIndex >= 0) {
                  this.menuData.splice(idIndex, 1);
                } else {
                  this.menuData.push(obj);
                }
              }
              if (val == "待毕业") {
                let obj = {
                  name: val,
                  index: "3",
                  type: "tograduate",
                  list: res.data[val],
                };
                let idIndex = this.menuDatas.indexOf(obj);
                if (idIndex >= 0) {
                  this.menuDatas.splice(idIndex, 1);
                } else {
                  this.menuDatas.push(obj);
                }
              }
              if (val == "已毕业") {
                console.log(val);
                let obj = {
                  name: val,
                  index: "4",
                  type: "yetgraduate",
                  list: res.data[val],
                };
                let idIndex = this.menuDatas.indexOf(obj);
                if (idIndex >= 0) {
                  this.menuDatas.splice(idIndex, 1);
                } else {
                  this.menuDatas.push(obj);
                }
              }
            }
          });
      } else {
        this.menuData = [];
        let data = {
          schoolId: this.$store.state.userInfo.schoolId,
          teacherId: this.$store.state.userInfo.teacherId,
        };
        this.api.school.getTeacherGradeLabel(data).then((res) => {
          console.log(data, "老师111111111111111111");
          for (let val in res.data) {
            if (val == "小班" || val == "中班" || val == "大班") {
              let obj = {
                name: val,
                type: "ban",
                list: res.data[val],
              };
              let idIndex = this.menuData.indexOf(obj);
              if (idIndex >= 0) {
                this.menuData.splice(idIndex, 1);
              } else {
                this.menuData.push(obj);
              }
            }
            if (val == "待毕业") {
              let obj = {
                name: val,
                index: "3",
                type: "tograduate",
                list: res.data[val],
              };
              let idIndex = this.menuDatas.indexOf(obj);
              if (idIndex >= 0) {
                this.menuDatas.splice(idIndex, 1);
              } else {
                this.menuDatas.push(obj);
              }
            }
            if (val == "已毕业") {
              console.log(val);
              let obj = {
                name: val,
                index: "4",
                type: "yetgraduate",
                list: res.data[val],
              };
              let idIndex = this.menuDatas.indexOf(obj);
              if (idIndex >= 0) {
                this.menuDatas.splice(idIndex, 1);
              } else {
                this.menuDatas.push(obj);
              }
            }
          }
        });
      }
    },

    // v2.0 默认所有学校学生和老师
    findSchoolInfo() {
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
      };
      this.api.school.findSchoolInfo(this.updateParams, data).then((res) => {
        console.log(this.formListClassAdd, "222222222222222222222");
        this.formListClassAdd[3].options = res.data.teacherList;
        this.formListClassAdd[3].options = res.data.teacherList.map((item) => {
          return { value: `${item.id}`, label: `${item.name}` };
        });
      });
    },

    // v2.0打开编辑弹窗
    bindAddClass() {
      this.$refs.comdialogClass.isOpen();
      this.alertStatusClass = "add";
      this.alertStatusClass = "新增班级";
      this.findSchoolInfo();
    },
    // v2.0 新增班级
    onSubmitClass(val) {
      console.log(val, "编辑班级");
      var result = val.some((item) => {
        if (item.name === "") {
          return true;
        }
      });
      var result1 = val.some((item) => {
        if (item.type === "") {
          return true;
        }
      });
      var result2 = val.some((item) => {
        if (item.open === "") {
          return true;
        }
      });
      if (result) {
        // 如果存在
        this.$message.error("请输入班级名称");
      } else if (result1) {
        this.$message.error("请选择所属年级");
      } else if (result2) {
        this.$message.error("请选择班级状态");
      } else {
        this.isLoading = true;
        let AtteGradeDto = {
          atteGradeList: this.atteGradeList,
          schoolId: this.$store.state.userInfo.schoolId,
        };
        this.api.school.addGradeBatch(AtteGradeDto).then((res) => {
          console.log(res);
          if (res.flag) {
            this.$notice({
              type: "success",
              message: res.message,
            }).isShow();
            this.isLoading = false;
            this.$refs.comdialogClass.isClose();
            if (this.$store.state.userInfo.teacherId) {
              this.$store.dispatch(
                "getFindTeachersInfo",
                this.$store.state.userInfo.teacherId
              );
            }
            this.$store.dispatch("getSchoolInfo");
            this.menuDatas = [];
            this.getClassInfo();
            this.atteGradeList = [
              {
                name: "",
                type: "",
                open: "",
                teacherIdList: [],
              },
            ];
          } else {
            this.$notice({
              type: "warn",
              message: res.message,
            }).isShow();
            this.isLoading = false;
          }
        });
      }
    },
    // v2.0关闭编辑弹窗
    comdialogCloseClass() {
      this.atteGradeList = [
        {
          name: "",
          type: "",
          open: "",
          teacherIdList: [],
        },
      ];
      this.isLoading = false;
    },
  },
  watch: {},
  components: {
    riseDialog,
    comdialog,
    // classCof,
    classAllCof,
    classBanCof,
    classGraCof,
    classGraYCof,
    classUndCof,
    classNotCof,
    classDelCof,
    classSecCof,
  },
};
</script>
<style lang="scss" scoped>
.class {
  background: #fff;
  .class-title {
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ba3037;
    margin-bottom: 18px;
  }
}

.class-content {
  display: flex;
  justify-content: space-between;
  .class-content-left {
    width: 245px;
    box-shadow: 0px 2px 12px 0px rgba(189, 189, 189, 0.41);
    border-radius: 2px;
    .class-left-head {
      width: 245px;
      height: 64px;
      background: #ba3037;
      border-radius: 4px 4px 0px 0px;
      font-size: 18px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #ffffff;
      .class-left-head-pad {
        padding: 0 20px;
        height: 64px;
        @include flex(row, space-between, center);
        cursor: pointer;
      }
      span {
        display: block;
        font-size: 20px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #ffffff;
      }
      .bindClassAll {
        width: 84px;
        height: 28px;
        background: #ffffff;
        box-shadow: 0px 3px 6px 0px #d23a41;
        border-radius: 14px;
        font-size: 16px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #ba3037;
        text-align: center;
        line-height: 28px;
        cursor: pointer;
      }
    }
    .class-left-list {
      cursor: pointer;
      .menu-item-title {
        height: 48px;
        display: flex;
        align-items: center;
        padding: 0 20px;
        font-size: 18px;
        font-family: Adobe Heiti Std;
        font-weight: bold;
        color: #33333d;
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .menu-item-add,
      .menu-item-1-title {
        height: 48px;
        display: flex;
        align-items: center;
        padding: 0 20px;
        font-size: 18px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #33333d;
        border-bottom: 1px solid #e1e1e1;
        position: relative;
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .menu-item-1-2-con {
        height: 48px;
        display: flex;
        align-items: center;
        background: #f1f1f1;
        padding: 0 20px;
        font-size: 18px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #33333d;
        border-bottom: 1px solid #e1e1e1;
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        p {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .active {
        border-left: 3px solid #ba3037;
        color: #ba3037;
      }
      .menu-item-1-title:after {
        content: "";
        width: 6px;
        height: 6px;
        border-left: 1px solid #666;
        border-bottom: 1px solid #666;
        transform: rotate(225deg);
        -webkit-transform: rotate(225deg);
        position: absolute;
        right: 9px;
        top: 20px;
      }

      .activeSan:after {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }
      .menu-item-1-title-year {
        height: 48px;
        display: flex;
        align-items: center;
        padding: 0 20px;
        font-size: 18px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #333333;
        border-bottom: 1px solid #e1e1e1;
        position: relative;
        background: #e1e1e1;
      }
      .menu-item-1-title-year:after {
        content: "";
        width: 6px;
        height: 6px;
        border-left: 1px solid #666;
        border-bottom: 1px solid #666;
        transform: rotate(225deg);
        -webkit-transform: rotate(225deg);
        position: absolute;
        right: 9px;
        top: 20px;
      }

      .activeSan:after {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }
    }
  }
}
.menu {
  cursor: pointer;
}
.el-collapse {
  /deep/ .el-collapse-item__header {
    justify-content: flex-end !important;
    font-size: 18px;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #33333d;
  }
  /deep/ .el-collapse-item__arrow {
    margin: 0 !important;
  }
  /deep/ .el-collapse-item__content {
    padding-bottom: 0 !important;
  }
  .menu-title {
    font-size: 18px;
    font-family: Adobe Heiti Std;
    font-weight: bold;
    color: #33333d;
    display: flex;
    align-content: center;
  }

  .menu-item {
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #33333d;
    height: 48px;
    font-size: 18px;
    line-height: 48px;
    text-align: right;
    border-top: 1px solid #f1f1f1;
    padding: 0 10px;
  }
  .active {
    border-left: 3px solid #ba3037;
  }
}
.class-right-head-input-fen {
  display: flex;
  height: 38px;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
}
.class-right-head-input-select {
  display: flex;
  align-items: center;
  .select-list {
    width: 200px;
  }
}
.input-fen-right {
  width: 300px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ba3037;
  border-radius: 4px;
  padding: 0 20px;
  font-size: 18px;
  input {
    border: none;
    color: #ba3037;
  }
  input::-webkit-input-placeholder {
    color: #888;
  }
  input::-moz-input-placeholder {
    color: #888;
  }
  input::-ms-input-placeholder {
    color: #888;
  }
  span {
    border-left: 1px solid #ba3037;
    padding-left: 20px;
    color: #ba3037;
    cursor: pointer;
  }
}
.class_go_up_btn {
  width: 136px;
  height: 56px;
  background: url(../../../assets/img/school/Btn_SB_Bg_U.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #ffffff;
  cursor: pointer;
  img {
    width: 19px;
    height: 18px;
    margin-right: 9px;
  }
}
.openActive {
  color: #888888;
}
.menu-item-1-title-other {
  border-top: 10px solid #f1f1f1;
}
</style>