<template>
  <div class="dialog_block">
    <el-dialog
      :title="title"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="isClose"
      :visible.sync="show"
    >
      <div slot="title" class="title_bg">
        <span class="dialog-title">
          {{ title }}
        </span>
        <img
          src="../../assets/img/school/Icon_AddClass.png"
          alt=""
          @click="addClass"
        />
      </div>
      <div class="form_box">
        <div class="form_box_label">
          <ul>
            <li>
              <span class="form_box_label_rue">*</span>
              <span>所选班级</span>
            </li>
            <li>
              <span class="form_box_label_rue">*</span>
              <span>班级名称</span>
            </li>
            <li>
              <span class="form_box_label_rue">*</span>
              <span>班级状态</span>
            </li>
            <li>
              <span>关联教师</span>
            </li>
          </ul>
        </div>
        <el-form
          v-for="(items, index) in form"
          :key="index"
          ref="comForm"
          :model="items"
        >
          <template v-for="item in list">
            <el-form-item
              :key="item.prop"
              :prop="item.prop || ''"
              :required="item.required || false"
              :rules="item.rules || null"
              :size="item.size || ''"
              class="form-item-inp"
            >
              <el-input
                v-if="item.type === 'text' && edit !== 'view'"
                :disabled="item.disabled"
                maxlength="20"
                v-model="form[index][item.prop]"
              ></el-input>
              <el-input
                v-else-if="item.type === 'number' && edit !== 'view'"
                :disabled="item.disabled"
                v-model="form[index][item.prop]"
              ></el-input>
              <el-input
                v-else-if="item.type === 'password' && edit !== 'view'"
                type="password"
                :disabled="item.disabled"
                v-model="form[index][item.prop]"
              ></el-input>
              <el-select
                v-else-if="item.type === 'select' && edit !== 'view'"
                :disabled="item.disabled"
                style="width: 100%"
                :multiple="item.multiple || false"
                v-model="form[index][item.prop]"
                placeholder="请选择"
              >
                <el-option
                  v-for="val in item.options"
                  :key="val.value"
                  :label="val.label"
                  :value="val.value"
                >
                </el-option>
              </el-select>
              <el-upload
                v-else-if="item.type === 'upload' && edit !== 'view'"
                :headers="{ token: $store.state.userInfo.token }"
                class="avatar-uploader"
                :action="constant.UPLOAD_URL + '/file/upload'"
                :show-file-list="false"
                :on-success="(v) => handleAvatarSuccess(v, item.prop)"
                :before-upload="beforeAvatarUpload"
              >
                <img
                  v-if="form[index][item.prop]"
                  :src="form[index][item.prop]"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <el-date-picker
                v-else-if="item.type === 'date' && edit !== 'view'"
                style="width: 100%"
                v-model="form[index][item.prop]"
                :disabled="item.disabled"
                :picker-options="pickerOptions"
                type="date"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              >
              </el-date-picker>
              <el-radio-group
                v-else-if="item.type === 'radio' && edit !== 'view'"
                :disabled="item.disabled"
                v-model="form[index][item.prop]"
                @change="(val) => changeCardType(val, item)"
              >
                <el-radio
                  :label="val.value"
                  v-for="val in item.options"
                  :key="val.value"
                  >{{ val.label }}</el-radio
                >
              </el-radio-group>
              <span>{{ item.default }}</span>
              <span v-if="edit === 'view' && item.type !== 'upload'">{{
                form[index][item.prop]
              }}</span>
              <!-- <img v-else :src="form[item.prop]" /> -->
              <el-image
                v-if="
                  edit === 'view' && item.type === 'upload' && form[item.prop]
                "
                style="width: 1rem; height: 1rem"
                :src="form[index][item.prop]"
                :z-index="20000"
                :preview-src-list="[form[index][item.prop]]"
              >
              </el-image>
            </el-form-item>
          </template>
        </el-form>
      </div>
      <slot name="desc"></slot>
      <div style="text-align: center" class="button-btn">
        <el-button
          v-if="edit !== 'view'"
          type="primary"
          @click="submitForm"
          :loading="isLoading"
          >提交</el-button
        >
        <el-button @click="isClose">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "tablePage",
  components: {},
  props: {
    title: {
      type: String,
      default: "",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    // 表单配置
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 初始表单数据
    params: {
      type: Array,
      default: () => {
        return {};
      },
    },
    // edit是否是编辑状态
    edit: {
      type: String,
      default: "add",
    },
  },
  watch: {
    params: {
      immediate: true,
      handler: function (val) {
        console.log(val);
        this.form = val;
      },
    },
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        },
      },
      form: [],
      show: false,
      column: [],
    };
  },
  created() {},
  mounted() {},
  methods: {
    handleAvatarSuccess(res, prop) {
      this.form[prop] = res.data.url;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$notice({
          message: "上传头像图片只能是 JPG/PNG 格式!",
        }).isShow();
      }
      if (!isLt2M) {
        this.$notice({
          message: "上传头像图片大小不能超过 2MB!",
        }).isShow();
      }
      return isJPG && isLt2M;
    },
    submitForm() {
      const self = this;
      this.$emit("onSubmit", self.form);
    },
    isOpen() {
      this.show = true;
    },
    isClose() {
      this.show = false;
      this.$emit("close", "");
    },
    addClass() {
      this.$emit("addClass", "");
    },
    changeCardType(val, item) {
      if (item.prop === "idCardType") {
        this.$emit("changeCardType", val);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog_block {
  /deep/.el-dialog {
    margin-top: 10vh !important;
    border: none !important;
    width: 880px !important;
    border-radius: 10px !important;
  }
  /deep/.el-dialog__header {
    width: 880px;
    height: 50px;
    background: #cb1b32;
    padding: 0 !important;
    text-align: center;
    line-height: 50px;
    border-radius: 10px 10px 0 0;
    span {
      font-weight: bold;
      color: #ffffff !important;
      font-size: 18px;
    }
    .el-dialog__headerbtn .el-dialog__close {
      color: #ffffff !important;
    }
  }
  .el-form-item {
    margin-bottom: 22px;
    width: 180px;
  }
  .el-form {
    padding: 0 !important;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed #dddddd;
    margin-top: 20px;
  }
  .button-btn {
    display: flex;
    justify-content: center;
  }
  .el-button-primary {
    width: 110px;
    height: 49px;
    background: url(../../assets/img/online/Btn_Yjsk.png) no-repeat;
    background-size: 100% 100%;
    border-radius: 6px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 38px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    cursor: pointer;
  }
  .el-button-primary:hover {
    background: url(../../assets/img/online/Btn_Yjsk_hg.png) no-repeat;
    background-size: 100% 100%;
  }
  .el-button-default:hover {
    border: 1px solid #f13131;
    color: #f13131;
  }
  .el-button-default {
    width: 100px;
    height: 36px;
    border: 1px solid #cc1930;
    border-radius: 6px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #cc1930;
    line-height: 38px;
    margin-left: 50px;
    cursor: pointer;
  }
  .el-form:last-child {
    border: none;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  /deep/ .el-button--primary {
    width: 100px;
    height: 38px;
    background: linear-gradient(232deg, #cb1930, #ff4054);
    box-shadow: 0px 3px 6px 0px rgba(255, 34, 67, 0.33);
    border-radius: 6px;
    border: none;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    margin-right: 20px;
  }
  /deep/ .el-button--default {
    width: 100px;
    height: 38px;
    border: 1px solid #cc1930;
    border-radius: 6px;
    background: none;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #cc1930;
  }
  /deep/ .el-button--primary:hover {
    background: linear-gradient(-232deg, #ff7b80, #dc6166);
    box-shadow: 0px 3px 6px 0px rgba(255, 34, 67, 0.33);
  }
  /deep/ .el-button--default:hover {
    border: 1px solid #f13131;
    color: #f13131;
  }
}
.form_box {
  max-height: 400px;
  overflow: auto;
}
.form_box_label {
  ul {
    display: flex;
    justify-content: space-between;
    li {
      width: 180px;
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      display: flex;
      .form_box_label_rue {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ff0000;
        margin-right: 4px;
      }
    }
  }
}
.title_bg {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 26px;
    height: 28px;
    cursor: pointer;
    margin-top: -2px;
    margin-left: 6px;
  }
}
/deep/ .el-dialog__headerbtn {
  font-size: 20px;
  top: 15px;
}
</style>