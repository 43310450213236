<template>
  <div class="perfect">
    <div class="class-content">
      <div class="class-content-right">
        <div class="class-box">
          <div class="class-right-head">
            <div class="class-right-head-input">
              <div class="class-right-head-input-title">
                {{ banName }}
              </div>
              <div class="class-right-head-input-select" v-if="!$store.state.userInfo.teacherId">
                <el-dropdown>
                  <div
                    class="class-right-head-input-btn" 
                  >
                    导入教师
                  </div>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="addTeacher"
                      >新增教师</el-dropdown-item
                    >
                    <el-dropdown-item @click.native="handleTecComplete"
                      >批量导入</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
                <div class="select-list" v-if="isShowAlldivide">
                  <el-select v-model="selectValue" placeholder="请选择">
                    <el-option
                      v-for="item in classList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div class="upClass class-right-head-input-btn" @click="clickDivide">
                  批量分班
                </div>
              </div>
              
            </div>
            
          </div>
          <div class="class-stu">
            <div class="class-stu-chex">
              <h2>教师</h2>
            </div>
            <div class="class-tec-con">
              <ul>
                <li v-for="(item,index) in tecData" :key="index" @click="checkedTec(item)">
                  <input
                    type="checkbox"
                    name="checkboxinput"
                    class="input-checkbox"
                    :checked="fruitTecId.id == item.id"
                    v-if="isTctCek"
                  />
                  <div class="class-tec-con-item">
                    <img :src="item.photo != ''?constant.URL + item.photo:require('../../../../assets/img/online/icon_teacher.png')" alt="" />
                    <p class="tec-name">{{ item.name }}</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="class-stu-btn" v-if="isTctCek">
            <div class="class-stu-bind" @click="handleDel">确认</div>
            <div class="class-stu-cane" @click="handleCan">取消</div>
          </div>
          <!-- 多选模块 -->
          <div class="class-stu">
            <div class="class-stu-chex">
              <h2>幼儿</h2>
            </div>  
            <div class="class-sut-con" v-if="isChekModel">
              <ul>
                <li
                  v-for="(item, index) in stuData"
                  :key="index"
                  class="stu-class-bg"
                  @click="checkedStu(item)"
                >
                  <input
                    type="checkbox"
                    name="checkboxinput"
                    class="input-checkbox"
                    :checked="fruitIds.indexOf(item) >= 0"
                    v-if="isShowChec"
                  />
                  <div class="class-sut-con-item">
                    <p class="stu-name">{{ item.name }}</p>
                    <p class="stu-class">
                      {{
                        item.type == 0
                          ? "大班"
                          : item.type == 1
                          ? "中班"
                          : "小班"
                      }} 
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <div class="class-sut-con" v-else>
              <ul>
                <li
                  v-for="(item, index) in stuData"
                  :key="index"
                  class="stu-class-bg"
                  @click="audioChell(item)"
                >
                  <input
                    type="checkbox"
                    name="checkboxinput"
                    class="input-checkbox"
                    :checked="fruitStuIds == item.id"
                    v-if="isShowChec"
                  />
                  <div class="class-sut-con-item">
                    <p class="stu-name">{{ item.name }}</p>
                    <p class="stu-class">
                      {{
                        item.type == 0
                          ? "大班"
                          : item.type == 1
                          ? "中班"
                          : "小班"
                      }}  
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <div class="page" v-if="stuData.length > 0">
              <comPagination
                :params="updateParams"
                @changePage="changeUpadtePage"
              ></comPagination>
            </div>
          </div>
          <div class="class-stu-btn" v-if="isShowDelStu && stuData.length !=0">
            <div class="class-stu-bind" @click="handDelStu">确认</div>
            <div class="class-stu-cane" @click="handCanStu">取消</div>
          </div>
          <div class="class-stu-btn" v-if="isShowBtn == 1 && stuData.length !=0">
            <div class="class-stu-bind" @click="goGraduation">确认</div>
            <div class="class-stu-cane" @click="cancelGraduation">取消</div>
          </div>
          <div class="class-stu-btn" v-if="isShowBtn == 2 && stuData.length !=0">
            <div class="class-stu-bind" @click="updataByList">确认</div>
            <div class="class-stu-cane" @click="updataByCan">取消</div>
          </div>
          <div class="class-stu-btn" v-if="isShowBtn == 3 && stuData.length !=0">
            <div class="class-stu-bind" @click="updataZxList">确认</div>
            <div class="class-stu-cane" @click="updataZxCan">取消</div>
          </div>
          <!-- 移除列表恢复确定按钮 -->
          <div class="class-stu-btn" v-if="isShowBtn == 4 && stuData.length !=0">
            <div class="class-stu-bind" @click="transferBtnAff">确认</div>
            <div class="class-stu-cane" @click="transferBtnCan">取消</div>
          </div>
          <!-- 毕业恢复确定按钮 -->
          <div class="class-stu-btn" v-if="isShowBtn == 5 && stuData.length !=0">
            <div class="class-stu-bind" @click="graduateBtnAff">确认</div>
            <div class="class-stu-cane" @click="graduateBtnCan">取消</div>
          </div>
          <!-- 留级恢复确定按钮 -->
          <div class="class-stu-btn" v-if="isShowBtn == 6 && stuData.length !=0">
            <div class="class-stu-bind" @click="nextGradeBtnAff">确认</div>
            <div class="class-stu-cane" @click="nextGradeBtnCan">取消</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 增加学生 -->
    <comdialog
      :list="fromListStuAdd"
      ref="comdialogStu"
      :params.sync="stuInfo"
      :edit="alertStatusStu"
      :title="
        alertStatusStu === 'edit'
          ? '编辑学生'
          : alertStatusStu === 'add'
          ? '新增学生'
          : '查看学生'
      "
      @onSubmit="onSubmit"
      @close="comdialogClose"
      @changeCardType="changeStuCardType"
    ></comdialog>
    <!-- 增加教师 -->
    <Teacomdialog
      ref="comdialogTec"
      :params.sync="teacherInfo"
      :edit="alertStatusTec"
      :title="alertStatusTec === 'add' ? '新增教师' : '编辑教师'"
      @onSubmit="onSubmitTec"
      @close="comdialogCloseTec"
    ></Teacomdialog>
    <!-- 编辑班级 -->
    <comdialog
      :list="formListClassUp"
      ref="comdialogClass"
      :params.sync="classInfo"
      :edit="alertStatusClass"
      :title="alertStatusClass"
      @onSubmit="onSubmitClass"
      @close="comdialogCloseClass"
    ></comdialog>
    <el-dialog
      width="5.9rem"
      title="批量导入新增学生"
      class="handleStuAlertShow"
      :visible.sync="handleStuAlertShow1"
    >
      <p style="color: red; font-size: 0.16rem">
        *外籍或持护照等其它证件幼儿请通过“添加学生”录入
      </p>
      <div class="el-dialog-con">
        <div class="el-dialog-left">
          <div class="btn tab">
            <img src="../../../../assets/img/school/Icon_Tc_DownLoad.png" alt="">
          </div>
          <p class="title">下载标准格式的excel模板</p>
          <a href="https://www.prefootball.cn/assets/football/excel/%E5%AD%A6%E5%91%98%E4%BF%A1%E6%81%AF%E8%A1%A8.xls" class="dowm-btn">
            下载 
          </a>
        </div>
        <div class="el-dialog-left">
          <div class="btn tab" slot="trigger">
            <img src="../../../../assets/img/school/Icon_Tc_Upd.png" alt="">
          </div>
          <p class="title">上传已经填写完毕的excel表单</p>
          <el-upload
            class="upload-demo"
            name="tableFile"
            :disabled="uploadStatus"
            :headers="{ token: $store.state.userInfo.token }"
            :action="constant.UPLOAD_URL + '/atteGrade/excelFileAdd?type=1'"
            :on-success="uploadSuccess"
            :data="{ grade: gradeId }"
            :show-file-list="false"
            :before-upload="beforeFileUpload"
          >
              <div class="dowm-upload" slot="trigger">
                {{ uploadStatus ? "正在上传" : "上传" }}
              </div>
          </el-upload>
        </div>
      </div>   
    </el-dialog>

    <el-dialog
      width="5.9rem"
      title="批量完善学生信息"
      class="handleStuAlertShow"
      :visible.sync="handleStuAlertShow2"
    >
      <p style="color: red; font-size: 0.16rem">
        *外籍或持护照等其它证件幼儿请通过“添加学生”录入
      </p>
      <div class="el-dialog-con">
        <div class="el-dialog-left">
          <div class="btn tab">
            <img src="../../../../assets/img/school/Icon_Tc_DownLoad.png" alt="">
          </div>
          <p class="title">下载当前的学生名单</p>
          <a :href="downloadUrl" class="dowm-btn">
            下载 
          </a>
        </div>
        <div class="el-dialog-left">
          <div class="btn tab" slot="trigger">
            <img src="../../../../assets/img/school/Icon_Tc_Upd.png" alt="">
          </div>
          <p class="title">上传当前的学生名单</p>
          <el-upload
            class="upload-demo"
            name="tableFile"
            :disabled="uploadStatus"
            :headers="{ token: $store.state.userInfo.token }"
            :action="constant.UPLOAD_URL + '/atteGrade/excelFileAdd?type=2'"
            :on-success="uploadSuccess"
            :data="{ grade: gradeId }"
            :show-file-list="false"
            :before-upload="beforeFileUpload"
          >
            <div class="dowm-upload" slot="trigger">
              {{ uploadStatus ? "正在上传" : "上传" }}
            </div>
          </el-upload>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      width="5.9rem"
      title="批量导入教师"
      class="handleStuAlertShow"
      :visible.sync="handleStuAlertShow3"
    >
      <p style="color: red; font-size: 0.16rem">
        *提示：请先下载模版，填写完毕后再进行上传表单
      </p>
      <div class="el-dialog-con">
        <div class="el-dialog-left">
          <div class="btn tab">
            <img src="../../../../assets/img/school/Icon_Tc_DownLoad.png" alt="">
          </div>
          <p class="title">下载标准格式的excel模板</p>
          <a href="https://www.prefootball.cn/assets/football/excel/%E5%AD%A6%E5%91%98%E4%BF%A1%E6%81%AF%E8%A1%A8.xls" class="dowm-btn">
            下载 
          </a>
        </div>
        <div class="el-dialog-left">
          <div class="btn tab" slot="trigger">
            <img src="../../../../assets/img/school/Icon_Tc_Upd.png" alt="">
          </div>
          <p class="title">上传已经填写完毕的excel表单</p>
          <el-upload
            class="upload-demo"
            name="tableFile"
            :disabled="uploadStatus"
            :headers="{ token: $store.state.userInfo.token }"
            :action="constant.UPLOAD_URL + '/atteGrade/excelFileAdd?type=1'"
            :on-success="uploadSuccess"
            :data="{ grade: gradeId }"
            :show-file-list="false"
            :before-upload="beforeFileUpload"
          >
              <div class="dowm-upload" slot="trigger">
                {{ uploadStatus ? "正在上传" : "上传" }}
              </div>
          </el-upload>
        </div>
      </div>   
    </el-dialog>
    <!-- 学生模板 -->
    <el-dialog width="75%" class="batch_result" :visible.sync="handleuploadStatus" title="批量导入结果">
      <!-- <span class="dialog_title">批量导入结果</span> -->
      <div class="custTab">
        <table class="layui-table" lay-skin="nob">
          <thead>
            <tr class="dialog_table_th">
              <th class="dialog_table_th_icon">操作<span class="dialog_table_th_sl"></span></th>
              <th class="dialog_table_th_num">序号<span class="dialog_table_th_sl"></span></th>
              <th>姓名<span class="dialog_table_th_sl"></span></th>
              <th class="dialog_table_th_sex">性别<span class="dialog_table_th_sl"></span></th>
              <th class="dialog_table_th_birth">生日<span class="dialog_table_th_sl"></span></th>
              <th class="dialog_table_th_cardId">身份证号<span class="dialog_table_th_sl"></span></th>
              <th>家长姓名<span class="dialog_table_th_sl"></span></th>
              <th class="dialog_table_th_phone">家长电话</th>
            </tr>
          </thead>
          <tbody class="dialog_table_tbody">
            <tbody v-for="(item, index) in uploadList" :key="item.id" style="position:relative;overflow:hidden;">
              <tr  class="dialog_table_tr">
                <td class="dialog_table_th_icon">
                  <img src="../../../../assets/img/school/batch_del.png" width="16" height="16" @click="delStuData(item,index)" />
                </td>
                <td class="dialog_table_th_num">
                  <div class="inpsty" >{{ index }}</div>
                </td>
                <td>
                  <el-input
                    class="inpsty"
                    v-model.trim="item.name.result"
                    placeholder="请输入姓名"
                    resize="none"
                    @blur="handleUploadInput()"
                    @change="checkName(item)"
                  />
                </td>
                <td class="dialog_table_th_sex">
                  <el-input
                    class="inpsty"
                    v-model.trim="item.sex.result"
                    placeholder="请输入正确的性别，必填"
                    resize="none"
                    :rows="1"
                    @blur="handleUploadInput()"
                    @change="checkSex(item)"
                  />
                </td>
                <td class="dialog_table_th_birth">
                  <el-input
                    class="inpsty"
                    v-model.trim="item.brithDay.result"
                    placeholder="请输入正确的生日，必填"
                    resize="none"
                    @blur="handleUploadInput()"
                    @change="checkBirthday(item)"
                  />
                </td>
                <td class="dialog_table_th_cardId">
                  <el-input
                    class="inpsty"
                    v-model.trim="item.idCard.result"
                    placeholder="请输入正确的身份证号"
                    resize="none"
                    @blur="handleUploadInput()"
                    @change="checkIdCard(item)"
                  />
                </td>
                <td>
                  <el-input
                    class="inpsty"
                    v-model.trim="item.parentName.result"
                    placeholder="请补充家长姓名"
                    resize="none"
                    @blur="handleUploadInput()"
                    @change="checkParentName(item)"
                  />
                </td>
                <td class="dialog_table_th_phone">
                  <el-input
                    class="inpsty"
                    v-model.trim="item.phone.result"
                    placeholder="请补充家长手机号"
                    resize="none"
                    @blur="handleUploadInput()"
                    @change="checkPhone(item)"
                  />
                  <input
                    v-if="index === (uploadList.length - 1)"
                    style="display:block;width:0px;height:0px;opacity:0;"
                    placeholder=""
                    resize="none"
                  />
                </td>
              </tr>
              <tr style="height:.3125rem;">
                <div v-if="item.isDuplicate && uploadType == 1" class="err_tip">
                  <div>
                    <img src="../../../../assets/img/school/notice.png" width="10" height="10" />
                    <span>该学员已经存在，请勿重复录入</span>
                  </div>
                </div>
                <div v-else-if="!item.isDuplicate && uploadType == 2" class="err_tip">
                  <div>
                    <img src="../../../../assets/img/school/notice.png" width="10" height="10" />
                    <span>此学员非本班学员，新学员请新增录入</span>
                  </div>
                </div>
                <div v-else >
                  <div v-if="!item.name.result || !item.name.flag" class="err_tip name">
                    <img src="../../../../assets/img/school/notice.png" width="10" height="10" />
                    <span>请输入正确姓名</span>
                  </div>
                  <div v-if="!item.sex.result || !item.sex.flag" class="err_tip sex">
                    <img src="../../../../assets/img/school/notice.png" width="10" height="10" />
                    <span>请输入正确性别</span>
                  </div>
                  <div v-if="!item.brithDay.result || !item.brithDay.flag" class="err_tip brithDay">
                    <img src="../../../../assets/img/school/notice.png" width="10" height="10" />
                    <span>请输入正确生日</span>
                  </div>
                  <div v-if="(uploadType == 2 && !item.idCard.result) || !item.idCard.flag" class="err_tip idCard">
                    <img src="../../../../assets/img/school/notice.png" width="10" height="10" />
                    <span>请输入正确身份证号</span>
                  </div>
                  <div v-if="(uploadType == 2 && !item.parentName.result) || !item.parentName.flag" class="err_tip parentName">
                    <img src="../../../../assets/img/school/notice.png" width="10" height="10" />
                    <span>请输入正确家长姓名</span>
                  </div>
                  <div v-if="(uploadType == 2 && !item.phone.result) || !item.phone.flag" class="err_tip phone">
                    <img src="../../../../assets/img/school/notice.png" width="10" height="10" />
                    <span>请输入正确家长电话</span>
                  </div>
                </div>
              </tr>
            </tbody>
          </tbody>
        </table>
      </div>
      <div style="padding:0.95rem 2.0rem; margin-top: -10px; display: flex;background:#FFFFFF;justify-content: space-between;
    align-items: flex-end;">
        <div class="btn_container" >
          <el-button
            class="btn_sty"
            @click="confirmEnd"
            :disabled="!uploadDialogOperate"
            >确认提交</el-button
          >
        </div>
        <div
          class="btn_container"
        >
          <el-button
            class="btn_sty"
            @click="postNow"
            :disabled="!uploadDialogOperate"
            >已经补充并提交</el-button
          >
        </div>
        <div class="btn_container">
          <el-button
            class="btn_sty"
            @click="postAgain"
            >放弃本次结果重新导入</el-button
          >
        </div>
      </div>
    </el-dialog>

    <!-- 老师模板 -->
    <el-dialog width="75%" class="batch_result" :visible.sync="handleuploadTec" title="批量导入结果">
      <!-- <span class="dialog_title">批量导入结果</span> -->
      <div class="custTab">
        <table class="layui-table" lay-skin="nob">
          <thead>
            <tr class="dialog_table_th">
              <th class="dialog_table_th_icon">操作<span class="dialog_table_th_sl"></span></th>
              <th class="dialog_table_th_num">序号<span class="dialog_table_th_sl"></span></th>
              <th>姓名<span class="dialog_table_th_sl"></span></th>
              <th class="dialog_table_th_sex">性别<span class="dialog_table_th_sl"></span></th>
              <th class="dialog_table_th_cardId">身份证号<span class="dialog_table_th_sl"></span></th>
              <th class="dialog_table_th_phone">登录账号</th>
              <th>确认密码<span class="dialog_table_th_sl"></span></th>   
            </tr>
          </thead>
          <tbody class="dialog_table_tbody">
            <tbody v-for="(item, index) in uploadList" :key="item.id" style="position:relative;overflow:hidden;">
              <tr  class="dialog_table_tr">
                <td class="dialog_table_th_icon">
                  <img src="../../../../assets/img/school/batch_del.png" width="16" height="16" @click="delStuData(item,index)" />
                </td>
                <td class="dialog_table_th_num">
                  <div class="inpsty" >{{ index }}</div>
                </td>
                <td>
                  <el-input
                    class="inpsty"
                    v-model.trim="item.name.result"
                    placeholder="请输入姓名"
                    resize="none"
                    @blur="handleUploadInput()"
                    @change="checkName(item)"
                  />
                </td>
                <td class="dialog_table_th_sex">
                  <el-input
                    class="inpsty"
                    v-model.trim="item.sex.result"
                    placeholder="请输入正确的性别，必填"
                    resize="none"
                    :rows="1"
                    @blur="handleUploadInput()"
                    @change="checkSex(item)"
                  />
                </td>
                <td class="dialog_table_th_cardId">
                  <el-input
                    class="inpsty"
                    v-model.trim="item.idCard.result"
                    placeholder="请输入正确的身份证号"
                    resize="none"
                    @blur="handleUploadInput()"
                    @change="checkIdCard(item)"
                  />
                </td>
                <td>
                  <el-input
                    class="inpsty"
                    v-model.trim="item.parentName.result"
                    placeholder="请输入确认密码"
                    resize="none"
                    @blur="handleUploadInput()"
                    @change="checkParentName(item)"
                  />
                </td>
                <td class="dialog_table_th_phone">
                  <el-input
                    class="inpsty"
                    v-model.trim="item.phone.result"
                    placeholder="请输入登录账号"
                    resize="none"
                    @blur="handleUploadInput()"
                    @change="checkPhone(item)"
                  />
                  <input
                    v-if="index === (uploadList.length - 1)"
                    style="display:block;width:0px;height:0px;opacity:0;"
                    placeholder=""
                    resize="none"
                  />
                </td>
              </tr>
              <tr style="height:.3125rem;">
                <div v-if="item.isDuplicate && uploadType == 1" class="err_tip">
                  <div>
                    <img src="../../../../assets/img/school/notice.png" width="10" height="10" />
                    <span>该学员已经存在，请勿重复录入</span>
                  </div>
                </div>
                <div v-else-if="!item.isDuplicate && uploadType == 2" class="err_tip">
                  <div>
                    <img src="../../../../assets/img/school/notice.png" width="10" height="10" />
                    <span>此学员非本班学员，新学员请新增录入</span>
                  </div>
                </div>
                <div v-else >
                  <div v-if="!item.name.result || !item.name.flag" class="err_tip name">
                    <img src="../../../../assets/img/school/notice.png" width="10" height="10" />
                    <span>请输入正确姓名</span>
                  </div>
                  <div v-if="!item.sex.result || !item.sex.flag" class="err_tip sex">
                    <img src="../../../../assets/img/school/notice.png" width="10" height="10" />
                    <span>请输入正确性别</span>
                  </div>
                  <div v-if="!item.brithDay.result || !item.brithDay.flag" class="err_tip brithDay">
                    <img src="../../../../assets/img/school/notice.png" width="10" height="10" />
                    <span>请输入正确生日</span>
                  </div>
                  <div v-if="(uploadType == 2 && !item.idCard.result) || !item.idCard.flag" class="err_tip idCard">
                    <img src="../../../../assets/img/school/notice.png" width="10" height="10" />
                    <span>请输入正确身份证号</span>
                  </div>
                  <div v-if="(uploadType == 2 && !item.parentName.result) || !item.parentName.flag" class="err_tip parentName">
                    <img src="../../../../assets/img/school/notice.png" width="10" height="10" />
                    <span>请输入正确家长姓名</span>
                  </div>
                  <div v-if="(uploadType == 2 && !item.phone.result) || !item.phone.flag" class="err_tip phone">
                    <img src="../../../../assets/img/school/notice.png" width="10" height="10" />
                    <span>请输入正确家长电话</span>
                  </div>
                </div>
              </tr>
            </tbody>
          </tbody>
        </table>
      </div>
      <div style="padding:0.95rem 2.0rem; margin-top: -10px; display: flex;background:#FFFFFF;justify-content: space-between;
    align-items: flex-end;">
        <div class="btn_container" >
          <el-button
            class="btn_sty"
            @click="confirmEnd"
            :disabled="!uploadDialogOperate"
            >确认提交</el-button
          >
        </div>
        <div
          class="btn_container"
        >
          <el-button
            class="btn_sty"
            @click="postNow"
            :disabled="!uploadDialogOperate"
            >已经补充并提交</el-button
          >
        </div>
        <div class="btn_container">
          <el-button
            class="btn_sty"
            @click="postAgain"
            >放弃本次结果重新导入</el-button
          >
        </div>
      </div>
    </el-dialog>

    <riseDialog
      ref="riseDialog"
      @onSubmit="bindUPclass"
      @close="comdialogCloserise"
    ></riseDialog>
  </div>
</template>
<script>
import comdialog from "@/components/admin/editDialog.vue";
import Teacomdialog from "@/components/admin/editTeacherDialog.vue";
import comPagination from "@/components/admin/pagination.vue";
import riseDialog from "@/components/admin/riseDialog.vue";
export default {
  props: {
    banName: {
      // 班级名称
      type: String,
      default: "全部班级",
    },
    gradeId: {
      type: String,
      default: "",
    },
    isContent: {
      // 显示模块判断值
      type: String,
      default: () => "",
    },
    banType: {
      // 班级类型
      type: String,
      default: () => "",
    },
  },
  data() {
    var checkName = (rule, value, callback) => {
      if (!this.common.textNumberCheck(value.trim(), 25)) {
        callback(new Error("请输入正确格式姓名"));
      } else {
        callback();
      }
    };
    var checkIdCard = (rule, value, callback) => {
      if (this.stuCardType == 0) {
        if (value.trim()) {
          if (!this.common.validateIdCard(value)) {
            callback(new Error("请输入正确格式身份证号"));
          } else {
            callback();
          }
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var checkParentName = (rule, value, callback) => {
      if (value.trim()) {
        if (!this.common.textNumberCheck(value, 25)) {
          callback(new Error("请输入正确格式家长姓名"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var checkPhone = (rule, value, callback) => {
      if (!value.trim()) {
        callback();
      } else {
        if (!this.common.isMobile(+value)) {
          callback(new Error("请输入正确格式家长联系电话"));
        } else {
          callback();
        }
      }
    };
    return {
      isTypePage: false, // 判断显示分页
      seachVal: "", // 查询文本
      isBtn: "", // 判断显示按钮
      isTctCek: false, // 判断老师删除
      updateParams: {
        // 分页
        page: 0,
        size: 50,
        total: 0,
      },
      isShowAlldivide: false,
      isChekModel: true, // 判断单选或者多选
      isoperate: true, // 操作教师是否显示
      isreplyZBtn: false, // 显示转学恢复按钮
      isreplyBBtn: false, // 显示毕业恢复按钮
      classList: "", // 分班选择框
      selectValue: "", // 分班选择班级
      gradeIds: "",
      gradeIdsValue: [],
      isShowDelStu: false, // 判断学生删除按钮
      isShowBtn: "", // 学生毕业按钮
      isLook: false, //判断查看弹出框
      isjudgeTec: true, // 判断老师是否选择.或修改查看
      isjudgeStu: true, // 判断学生是否选择.或修改查看
      menuData: {}, //学校班级列表
      activeName: "", // 学校默认高亮值
      handleStuAlertShow1: false, // 批量添加学生
      handleStuAlertShow2: false, // 批量完善学生
      handleStuAlertShow3: false, // 批量天假老师
      handleuploadStatus: false, // 上传成功后显示
      handleuploadTec: false,
      isShowChec: false, // 判断全选是否显示
      downloadUrl: "",
      uploadStatus: false,
      show: "3",
      isShow: "7",
      fruitIds: [], // 学生选择状态
      fruitStuIds: "", // 学生选择状态
      fruitTecId: "", // 老师选择id
      alertId: "",
      planStudents: "", //本班级已配课学生
      studentCount: "", // 本班级学生数量
      teacherCount: "", // 本班级老师数量
      // 弹窗状态  新增/编辑/查看
      // 学生信息弹窗
      stuInfoShow: true,
      alertStatusStu: "",
      alertStatusTec: "",
      alertStatusClass: "",
      stuCardType: 0,
      uploadList: [],
      uploadDialogOperate: true,
      classInfo: {
        name: "",
        value: "",
        state: "",
        rele: "",
      },
      teacherInfo: {
        name: "",
        sex: "1",
        natives: "",
        graduateSchool: "",
        education: "",
        idCard: "",
        teacherCertificateNumber: "",
        certificateTime: "",
        birthday: "",
        gradeIds: "",
        gradeIdsValue: "",
        photo: "",
        phone: "",
        password: "",
        againPassword: "",
        email: "",
      },
      stuInfo: {
        name: "",
        idCardType: 0,
        idCard: "",
        parentName: "",
        photo: "",
        birthday: "",
        gradeId: "",
        sex: 1,
        phone: "",
      },
      formListClassUp: [
        {
          prop: "value",
          type: "select",
          label: "所带班级",
          multiple: false,
          rules: [
            { required: true, message: "请选择所带班级", trigger: "blur" },
          ],
          options: [
            {
              label: "小班",
              value: 2,
            },
            {
              label: "中班",
              value: 1,
            },
            {
              label: "大班",
              value: 0,
            },
          ],
        },
        {
          prop: "name",
          type: "text",
          label: "班级名称",
          multiple: true,
          rules: [
            {
              required: true,
              message: "请输入班级名称",
              trigger: "blur",
            },
          ],
        },
        {
          prop: "state",
          type: "select",
          label: "班级状态",
          multiple: false,
          rules: [
            { required: true, message: "请选择班级状态", trigger: "blur" },
          ],
          options: [
            {
              label: "开启",
              value: 2,
            },
            {
              label: "关闭",
              value: 1,
            },
          ],
        },
        {
          prop: "rele",
          type: "select",
          label: "关联老师",
          multiple: false,
          rules: [
            { required: true, message: "请选择关联老师", trigger: "blur" },
          ],
          options: [
            {
              label: "开启",
              value: 2,
            },
            {
              label: "关闭",
              value: 1,
            },
          ],
        },
      ],
      fromListStuAdd: [
        {
          prop: "name",
          type: "text",
          label: "学生姓名",
          rules: [
            { required: true, message: "请输入姓名", trigger: "blur" },
            { validator: checkName, trigger: "blur" },
          ],
        },
        {
          prop: "idCardType",
          type: "radio",
          label: "证件类型",
          required: true,
          options: [
            {
              label: "身份证",
              value: 0,
            },
            {
              label: "护照",
              value: 1,
            },
            {
              label: "其他证件",
              value: 2,
            },
          ],
        },
        {
          prop: "idCard",
          type: "text",
          label: "证件号",
          rules: [
            { required: true, message: "请输入证件号", trigger: "blur" },
            { validator: checkIdCard, trigger: "blur" },
          ],
        },
        {
          prop: "parentName",
          type: "text",
          label: "家长姓名",
          rules: [
            { required: true, message: "请输入家长姓名", trigger: "blur" },
            { validator: checkParentName, trigger: "blur" },
          ],
        },
        {
          prop: "photo",
          type: "upload",
          label: "学员照片",
          required: false,
        },
        {
          prop: "birthday",
          type: "date",
          label: "学员出生日期",
          rules: [
            { required: true, message: "请选择学员出生日期", trigger: "blur" },
          ],
        },
        {
          prop: "gradeId",
          type: "select",
          label: "学员所属班级",
          options: [],
          rules: [
            { required: true, message: "请选择学员所属班级", trigger: "blur" },
          ],
        },
        {
          prop: "sex",
          type: "radio",
          label: "学员性别",
          required: true,
          options: [
            {
              label: "男",
              value: 1,
            },
            {
              label: "女",
              value: 2,
            },
          ],
        },
        {
          prop: "phone",
          type: "number",
          label: "家长联系电话",
          rules: [
            { required: true, message: "请输入家长联系电话", trigger: "blur" },
            { validator: checkPhone, trigger: "blur" },
          ],
        },
      ],
      // 初始化全选按钮, 默认不选
      isCheckedAll: false,
      stuData: [], // 学生列表
      tecData: [], // 老师列表
      options: [],
      value: "",
      defaultProps: {
        children: "children",
        label: "label",
      },
    };
  },
  mounted() {
    console.log(this.gradeId, "gradeId1111");
    this.init();
  },
  methods: {
    init() {
      this.getSeach(this.gradeId);
      this.getBanInfo();
    },
    //校验名称
    checkName(data) {
      //名称
      if (data.name.result) {
        if (!this.common.textNumberCheck(data.name.result.trim(), 25)) {
          this.$set(data.name, "flag", false);
        } else {
          this.$set(data.name, "flag", true);
        }
      } else {
        this.$set(data.name, "flag", true);
      }
    },
    //校验性别
    checkSex(data) {
      //性别
      if (data.sex.result) {
        if (!this.common.validateSex(data.sex.result.trim())) {
          this.$set(data.sex, "flag", false);
        } else {
          this.$set(data.sex, "flag", true);
        }
      } else {
        this.$set(data.sex, "flag", true);
      }
    },
    //校验生日
    checkBirthday(data) {
      //生日
      if (data.brithDay.result) {
        if (!this.common.validateBirthday(data.brithDay.result.trim())) {
          this.$set(data.brithDay, "flag", false);
        } else {
          this.$set(data.brithDay, "flag", true);
        }
      } else {
        this.$set(data.brithDay, "flag", true);
      }
    },
    //校验身份证号
    checkIdCard(data) {
      //身份证
      if (data.idCard.result) {
        if (!this.common.validateIdCard(data.idCard.result.trim())) {
          this.$set(data.idCard, "flag", false);
        } else {
          this.$set(data.idCard, "flag", true);
        }
      } else {
        this.$set(data.idCard, "flag", true);
      }
    },
    //校验家长名称
    checkParentName(data) {
      if (data.parentName.result) {
        if (!this.common.textNumberCheck(data.parentName.result.trim(), 25)) {
          this.$set(data.parentName, "flag", false);
        } else {
          this.$set(data.parentName, "flag", true);
        }
      } else {
        this.$set(data.parentName, "flag", true);
      }
    },
    //校验家长手机号
    checkPhone(data) {
      //手机号
      if (data.phone.result) {
        if (!this.common.isMobile(data.phone.result.trim())) {
          this.$set(data.phone, "flag", false);
        } else {
          this.$set(data.phone, "flag", true);
        }
      } else {
        this.$set(data.phone, "flag", true);
      }
    },
    // V2.0获取默认学生.老师,已配课老师数量
    findGradeInfo() {
      let data = {
        gradeId: this.gradeId,
        schoolId: this.$store.state.userInfo.schoolId,
      };
      this.api.school.findGradeInfo(data).then((res) => {
        console.log(res, "查询当前老师");
        this.planStudents = res.data.planStudents;
        this.studentCount = res.data.studentCount;
        this.teacherCount = res.data.teacherCount;
        this.tecData = res.data.teacherList;
      });
    },

    // v2.0学生毕业
    graduationStudent() {
      let transParamList = this.fruitIds.map((item) => {
        return {
          gradeId: item.gradeId,
          stuId: item.id,
        };
      });
      // let data = {
      //   gradeId: this.gradeId,
      //   ids: str,
      // };
      this.api.school.batchGraduation(transParamList).then((res) => {
        if (res.flag) {
          this.$notice({
            type: "success",
            message: "批量毕业学生成功",
          }).isShow();

          this.getStuInfo();
        } else {
          this.$notice({
            message: res.message,
          }).isShow();
        }
      });
    },
    // v2.0毕业操作
    goGraduation() {
      if (this.fruitIds.length != 0) {
        this.graduationStudent();
        this.cancelGraduation();
      } else {
        this.$confirm("请选择要毕业的学员", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });
      }
    },
    // v2.0 学校升班
    promotion() {
      this.$refs.riseDialog.isOpen();
    },
    bindUPclass() {
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
      };
      this.api.school.promotion(data).then((res) => {
        if (res.code == 20000) {
          this.$notice({
            type: "success",
            message: res.message,
          }).isShow();
          this.$refs.riseDialog.isClose();
        } else {
          this.$notice({
            type: "error",
            message: res.message,
          }).isShow();
          this.$refs.riseDialog.isClose();
        }
      });
    },
    comdialogCloserise() {
      this.$refs.riseDialog.isClose();
    },
    // v2.0批量毕业取消
    cancelGraduation() {
      this.isShowBtn = "";
      this.isShowChec = false;
      this.isLook = false;
      this.multipleSelection = [];
    },
    // v2.0批量毕业确认
    clickGraduation() {
      this.fruitIds = [];
      this.isShowChec = true;
      this.isLook = true;
      this.isShowBtn = 1;
      this.isShowAlldivide = false;
    },
    // v2.0 批量分班
    clickDivide() {
      this.fruitIds = [];
      this.isShowChec = true;
      this.isLook = true;
      this.isShowAlldivide = true;
      this.isShowBtn = 2;
    },
    // 批量分班
    updataByList() {
      if (!this.fruitIds.length) {
        this.$notice({
          message: "请先选择批量分班的学生",
        }).isShow();
        return;
      }
      if (!this.selectValue) {
        this.$notice({
          message: "请选择批量设置的班级",
        }).isShow();
        return;
      }
      let transParamList = this.fruitIds.map((item) => {
        return {
          gradeId: this.selectValue,
          stuId: item.id,
        };
      });
      this.api.school.batchChangeGrade(transParamList).then(() => {
        this.$notice({
          type: "success",
          message: "批量分班操作成功",
        }).isShow();
        this.updataByCan();
        if (this.gradeId == "all") {
          this.findSchoolInfo();
        } else {
          this.getStuInfo();
        }
      });
    },
    // 取消分班
    updataByCan() {
      this.selectValue = "";
      this.fruitIds = [];
      this.isShowChec = false;
      this.isLook = false;
      this.isShowBtn = "";
      this.isShowAlldivide = false;
    },

    // v2.0
    // v2.0 学校分班学校信息
    getClassList() {
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
      };
      this.api.nAdmin.findGradesBySchoolId(data).then((res) => {
        console.log(res, "班级列表");
        this.classList = res.data.map((item) => {
          return {
            label: item.name,
            value: item.id,
            ...item,
          };
        });
      });
    },
    // v2.0 选择学生查看或者编辑
    checkedStu(data) {
      if (this.isLook) {
        let idIndex = this.fruitIds.indexOf(data);
        if (idIndex >= 0) {
          // 如果已经包含了该id, 则去除(单选按钮由选中变为非选中状态)
          this.fruitIds.splice(idIndex, 1);
        } else {
          // 选中该checkbox
          this.fruitIds.push(data);
        }
      } else {
        console.log(data);
        this.$refs.comdialogStu.isOpen();
        this.alertStatusStu = "edit";
        this.stuInfo = {
          id: data.id,
          name: data.name,
          idCardType: data.idCardType,
          idCard: data.idCard,
          parentName: data.parentName,
          photo: data.photo ? this.constant.URL_admin + data.photo : "",
          birthday: data.birthday,
          gradeId: data.gradeId,
          sex: parseInt(data.sex),
          phone: data.phone,
        };
      }
    },
    // v2.0 选择老师查看或者编辑
    checkedTec(val) {
      if (this.isLook) {
        this.fruitTecId = val;
      } else {
        this.$refs.comdialogTec.isOpen();
        this.alertStatusTec = "edit";
        this.teacherInfo = {
          id: val.id,
          name: val.name,
          sex: val.sex,
          natives: val.natives,
          graduateSchool: val.graduateSchool,
          education: val.education,
          idCard: val.idCard,
          teacherCertificateNumber: val.teacherCertificateNumber,
          certificateTime: val.certificateTime,
          birthday: val.birthday,
          photo: val.photo
            ? "https://footballcn.oss-cn-beijing.aliyuncs.com/" + val.photo
            : "",
          phone: val.phone,
          password: val.password,
          againPassword: val.againPassword,
          email: val.email,
          gradeIds: val.gradeIds.split(","),
          gradeIdsValue: this.teacherInfo.gradeIdsValue,
          username: val.username,
          userId: val.userId,
        };
        // 获取老师所带班级
        this.getTeacherGrades(val.id);
      }
    },
    // v2.0获取老师所带班级
    getTeacherGrades(val) {
      let data = {
        teacherId: val,
      };
      this.api.admin.findGradesOfid(data).then((res) => {
        const grades = res.data;
        const that = this;
        let gradeIds = [];
        let gradeIdsValue = [];
        for (var x in grades) {
          gradeIds.push(grades[x].id);
          gradeIdsValue.push(grades[x].name);
        }
        that.teacherInfo.gradeIds = gradeIds;
        that.teacherInfo.gradeIdsValue = gradeIdsValue;
      });
    },
    //v2.0批量导入学生
    handleStuUpload() {
      this.$confirm(
        "请确认您进行的是新增操作，如果完善学生信息请使用“批量完善学生信息”功能。",
        "信息",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        }
      ).then(() => {
        this.uploadType = 1;
        this.handleStuAlertShow1 = true;
      });
    },
    //v2.0批量导入学生
    handleStuComplete() {
      this.uploadType = 2;
      this.handleStuAlertShow2 = true;
    },

    //v2.0批量导入教师
    handleTecComplete() {
      this.uploadType = 3;
      this.handleStuAlertShow3 = true;
    },
    beforeFileUpload(file) {
      console.log(file);
      if (this.uploadStatus) {
        this.$message.warning("文件正在上传中，请耐心等待");
        return false;
      } else {
        this.uploadStatus = true;
        return true;
      }
    },

    // 单选
    audioChell(data) {
      console.log(data.id);
      if (this.isLook) {
        console.log(1111111111);
        this.fruitStuIds = data.id;
      } else {
        this.$refs.comdialogStu.isOpen();
        this.alertStatusStu = "edit";
        this.stuInfo = {
          id: data.id,
          name: data.name,
          idCardType: data.idCardType,
          idCard: data.idCard,
          parentName: data.parentName,
          photo: data.photo ? this.constant.URL_admin + data.photo : "",
          birthday: data.birthday,
          gradeId: data.gradeId,
          sex: parseInt(data.sex),
          phone: data.phone,
        };
      }
    },
    // v2.0获取学校信息
    getBanInfo() {
      this.api.admin.findGradesDetails().then((res) => {
        let arr = res.data.map((item) => {
          return { label: item.atteGrade.name, value: item.atteGrade.id };
        });
        this.fromListStuAdd.forEach((item) => {
          if (item.prop === "gradeId") {
            item.options = arr;
          }
        });
      });
    },
    // v2.0 删除老师
    handleDel() {
      //恢复老师
      if (this.gradeId == "delList") {
        console.log("");
        this.api.school.recoveryTec(this.fruitTecId.id).then((res) => {
          console.log(res, "恢复老师");
          if (res.code == "20000") {
            this.$notice({
              type: "success",
              message: res.message,
            }).isShow();
          } else {
            this.$notice({
              type: "warn",
              message: res.message,
            }).isShow();
          }
          this.handleCan();
        });
      } else {
        this.isLook = true;
        this.isTctCek = true;
        let deleMessage = "此操作将删除该教师, 是否继续?";
        if (this.fruitTecId.integral) {
          deleMessage = "删除该教师将影响园所的积分";
        }
        this.$confirm(deleMessage, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            let data = {
              teacherId: this.fruitTecId.id,
              userId: this.fruitTecId.userId,
            };
            this.api.admin.atteTeacherDel(data).then((res) => {
              if (res.flag) {
                this.$notice({
                  type: "success",
                  message: "删除成功!",
                }).isShow();

                this.fruitTecId = "";
                this.isLook = false;
                this.isTctCek = false;
                this.$store.dispatch("getSchoolInfo");
              } else {
                this.$notice({
                  type: "warn",
                  message: "删除失败!",
                }).isShow();
              }
              // 判断模块
              this.getSeach(this.gradeId);
            });
          })
          .catch(() => {
            this.$notice({
              type: "warn",
              message: "已取消删除",
            }).isShow();
          });
      }
    },
    // v2.0取消删除老师
    handleCan() {
      this.fruitTecId = "";
      this.isLook = false;
      this.isTctCek = false;
      this.isShowChec = false;
      this.fruitStuIds = "";
    },
    // v2.0开始删除老师按钮
    delTec() {
      this.isLook = true;
      this.isTctCek = true;
    },
    // v2.0开始删除学生按钮
    delStu() {
      this.isLook = true;
      this.isShowChec = true;
      this.isShowDelStu = true;
      this.isChekModel = false;
    },
    // v2.0删除学生确认
    handDelStu() {
      let deleMessage = "此操作将永久删除该学员, 是否继续?";
      if (this.fruitStuIds.integral) {
        deleMessage = "删除该学员将影响园所和班级的积分和测试成绩";
      }
      this.$confirm(deleMessage, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.api.admin.updateDeleteStatus(this.fruitStuIds).then((res) => {
            this.moreHandleInfo = false;
            if (res.flag) {
              this.$notice({
                type: "success",
                message: "删除成功!",
              }).isShow();
              // 判断模块
              this.getSeach(this.gradeId);
              this.handCanStu();
              this.$store.dispatch("getSchoolInfo");
            } else {
              this.$notice({
                type: "warn",
                message: "删除失败!",
              }).isShow();
            }
          });
        })
        .catch(() => {
          this.$notice({
            type: "info",
            message: "已取消删除",
          }).isShow();
        });
    },
    // v2.0 取消删除学生
    handCanStu() {
      this.isLook = false;
      this.isShowChec = false;
      this.isShowDelStu = false;
      this.isChekModel = true;
      this.fruitStuIds = "";
    },
    // v2.0 批量转学
    clickTransfer() {
      this.isShowBtn = 3;
      this.isShowChec = true;
      this.isLook = true;
      this.isShowAlldivide = false;
    },
    // v2.0 批量转学
    updataZxList() {
      console.log(this.fruitStuIds);
      let transParamList = this.fruitIds.map((item) => {
        return {
          gradeId: item.gradeId,
          stuId: item.id,
        };
      });
      console.log(transParamList);
      this.api.school.batchTransfer(transParamList).then((res) => {
        console.log(res);
        if (res.flag) {
          this.$notice({
            type: "success",
            message: "转学成功!",
          }).isShow();
          this.getStuInfo();
          this.updataZxCan();
        } else {
          this.$notice({
            type: "warn",
            message: "转学失败!",
          }).isShow();
          this.getStuInfo();
          this.updataZxCan();
        }
      });
    },
    // v2.0 批量转学
    updataZxCan() {
      this.isShowBtn = "";
      this.isShowChec = false;
      this.isLook = false;
      this.fruitIds = [];
    },
    // v2.0 恢复转学
    getRecoverStu() {
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
        type: 1,
        name: "",
      };
      this.api.admin.findChangeStuList(this.updateParams, data).then((res) => {
        console.log(res, "查询学生");
        this.stuData = res.data.list;
        this.fruitStuIds = "";
        this.updateParams.total = res.data.total;
      });
    },
    // 点击转学恢复按钮
    bindRecover() {
      this.isShowBtn = 4;
      this.isChekModel = false;
      this.isShowChec = true;
      this.isLook = true;
    },
    // v2.0 移除恢复接口
    transferBtnAff() {
      this.api.school.recoveryStu(this.fruitStuIds).then((res) => {
        if (res.flag) {
          this.$notice({
            type: "success",
            message: "恢复成功",
          }).isShow();
          this.transferBtnCan();
          this.removeList();
        } else {
          this.$notice({
            message: res.message,
          }).isShow();
          this.transferBtnCan();
        }
      });
    },
    // 移除恢复取消
    transferBtnCan() {
      this.isShowChec = false;
      this.isLook = false;
      this.isChekModel = true;
      this.fruitStuIds = "";
      this.isShowBtn = "";
      this.isTctCek = false;
      this.fruitTecId = "";
    },

    // 毕业转学按钮
    bindGraduate() {
      this.isShowBtn = 5;
      this.isChekModel = false;
      this.isShowChec = true;
      this.isLook = true;
    },
    graduateBtnAff() {
      let data = {
        id: this.fruitStuIds,
      };
      this.api.admin.coveryGraduation(data).then((res) => {
        if (res.flag) {
          this.$notice({
            type: "success",
            message: "恢复成功",
          }).isShow();
          this.getGraduate();
          this.graduateBtnCan();
        } else {
          this.$notice({
            message: res.message,
          }).isShow();
        }
      });
    },
    // 取消毕业
    graduateBtnCan() {
      this.isShowChec = false;
      this.isLook = false;
      this.isChekModel = true;
      this.fruitStuIds = "";
      this.isShowBtn = "";
    },
    // 确定提交
    confirmEnd() {
      this.handleuploadStatus = false;
      let data = {
        isCorrect: this.uploadDialogOperate,
        isAllCorrect: this.isAllCorrect,
        dtos: this.uploadList,
      };
      this.api.admin
        .addOrupdateBatchStudentS(this.uploadType, data)
        .then((res) => {
          if (res.flag) {
            this.handleStuAlertShow1 = false;
            this.handleStuAlertShow2 = false;
            this.isAllCorrect = res.data.isAllCorrect;
            if (res.data.isAllCorrect) {
              this.$notice({
                type: "success",
                message: res.message,
              }).isShow();
              this.handleuploadStatus = false;
              this.getStuInfo();
            } else {
              if (!res.data.isCorrect) {
                this.$message({
                  message: "内容有误，请检查!",
                  type: "warning",
                  customClass: "zZindex",
                });
              }
              this.handleuploadStatus = true;
              this.uploadDialogOperate = res.data.isCorrect;
              this.uploadList = res.data.dtos;

              this.handleUploadInput();
            }
          } else {
            this.$notice({
              type: "success",
              message: res.message,
            }).isShow();
          }
        });
    },
    postNow() {
      this.handleuploadStatus = false;
      let data = {
        isCorrect: this.uploadDialogOperate,
        isAllCorrect: this.isAllCorrect,
        dtos: this.uploadList,
      };
      this.api.admin
        .addOrupdateBatchStudentS(this.uploadType, data)
        .then((res) => {
          if (res.flag) {
            this.handleStuAlertShow1 = false;
            this.handleStuAlertShow2 = false;
            this.isAllCorrect = res.data.isAllCorrect;
            console.log(res.data.isAllCorrect, "res.data.isAllCorrect");
            if (res.data.isAllCorrect) {
              this.$notice({
                type: "success",
                message: res.message,
              }).isShow();
              this.handleuploadStatus = false;
              this.getStuInfo();
            } else {
              if (!res.data.isCorrect) {
                this.$message({
                  message: "内容有误，请检查!",
                  type: "warning",
                  customClass: "zZindex",
                });
              }
              this.handleuploadStatus = true;
              this.uploadDialogOperate = res.data.isCorrect;
              this.uploadList = res.data.dtos;

              this.handleUploadInput();
            }
          } else {
            this.$notice({
              type: "success",
              message: res.message,
            }).isShow();
          }
        });
    },
    postAgain() {
      this.handleuploadStatus = false;
    },
    handleUploadInput() {
      let isAllTrue = true;
      this.uploadList.forEach((item) => {
        if (this.uploadType == 1) {
          if (
            !item.name.result ||
            !item.sex.result ||
            !item.brithDay.result ||
            !item.name.flag ||
            !item.sex.flag ||
            !item.brithDay.flag ||
            !item.idCard.flag ||
            !item.parentName.flag ||
            !item.phone.flag ||
            item.isDuplicate
          ) {
            isAllTrue = false;
            this.uploadDialogOperate = false;
            return;
          }
        } else {
          if (
            !item.name.result ||
            !item.sex.result ||
            !item.brithDay.result ||
            !item.idCard.result ||
            !item.parentName.result ||
            !item.phone.result ||
            !item.name.flag ||
            !item.sex.flag ||
            !item.brithDay.flag ||
            !item.idCard.flag ||
            !item.parentName.flag ||
            !item.phone.flag ||
            !item.isDuplicate
          ) {
            isAllTrue = false;
            this.uploadDialogOperate = false;
            return;
          }
        }
      });
      if (this.uploadList.length == 0) {
        isAllTrue = false;
      }
      this.uploadDialogOperate = isAllTrue;
    },
    //上传成功
    uploadSuccess(res) {
      this.uploadStatus = false;

      if (res.flag) {
        this.handleStuAlertShow1 = false;
        this.handleStuAlertShow2 = false;
        this.isAllCorrect = res.data.isAllCorrect;
        if (res.data.isAllCorrect) {
          this.$notice({
            type: "success",
            message: res.message,
          }).isShow();
          this.handleuploadStatus = false;
          this.getStuInfo();
        } else {
          if (!res.data.isCorrect) {
            this.$message({
              message: "内容有误，请检查!",
              type: "warning",
              customClass: "zZindex",
            });
          }
          this.handleuploadStatus = true;
          this.uploadDialogOperate = res.data.isCorrect;
          this.uploadList = res.data.dtos;

          //判断数据的准确性
          this.uploadList.forEach((item) => {
            this.checkName(item);
            this.checkSex(item);
            this.checkBirthday(item);
            this.checkIdCard(item);
            this.checkParentName(item);
            this.checkPhone(item);
          });
          this.handleUploadInput();
        }
      } else {
        this.$notice({
          type: "success",
          message: res.message,
        }).isShow();
      }
    },
    // 下载完善信息列表
    download() {
      this.api.admin.download({ gradeId: this.gradeId }).then((res) => {
        const content = res.data;
        const blob = new Blob([content], { type: "application/vnd.ms-excel" });
        if ("download" in document.createElement("a")) {
          const elink = document.createElement("a");
          elink.download = this.$route.query.name + "学生名单.xlsx";
          elink.style.display = "none";
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href);
          document.body.removeChild(elink);
          this.$notice({
            type: "success",
            message: "导出成功",
          }).isShow();
        }
      });
    },

    //关闭编辑弹窗
    comdialogCloseTec() {
      this.teacherInfo = {
        name: "",
        sex: "1",
        natives: "",
        graduateSchool: "",
        education: "",
        idCard: "",
        teacherCertificateNumber: "",
        certificateTime: "",
        birthday: "",
        gradeIds: "",
        photo: "",
        phone: "",
        password: "",
        againPassword: "",
        email: "",
        gradeIdsValue: [],
      };
    },
    //关闭编辑弹窗
    comdialogClose() {
      this.stuInfo = {
        name: "",
        idCardType: 0,
        idCard: "",
        parentName: "",
        photo: "",
        birthday: "",
        gradeId: "",
        sex: 1,
        phone: "",
      };
    },
    //添加学生,切换证件类型
    changeStuCardType(val) {
      this.stuCardType = val;
    },
    onSubmitTec(val) {
      // 新增
      if (this.alertStatusTec === "add") {
        console.log(val.gradeIds);
        let data = {
          atteTeacher: {
            name: val.name,
            sex: val.sex,
            natives: val.natives,
            graduateSchool: val.graduateSchool,
            education: val.education,
            idCard: val.idCard,
            teacherCertificateNumber: val.teacherCertificateNumber,
            certificateTime: val.certificateTime,
            birthday: val.birthday,
            gradeIds: val.gradeIds.toString(),
            photo: val.photo.split(".com")[1],
          },
          username: val.phone,
          email: val.email,
          password: val.password,
        };
        console.log(data);
        this.api.admin
          .atteTeacherAdd(this.$store.state.userInfo.schoolId, data)
          .then((res) => {
            if (res.flag) {
              this.$refs.comdialogTec.isClose();
              this.$store.dispatch("getSchoolInfo");
              this.$notice({
                type: "success",
                message: res.message,
              }).isShow();
              // 判断模块
              this.getSeach(this.gradeId);
            } else {
              this.$notice({
                message: res.message,
              }).isShow();
            }
          });
      } else {
        //提交编辑

        let data = {
          atteTeacher: {
            id: val.id,
            userId: val.userId,
            name: val.name,
            sex: val.sex,
            natives: val.natives,
            graduateSchool: val.graduateSchool,
            education: val.education,
            idCard: val.idCard,
            teacherCertificateNumber: val.teacherCertificateNumber,
            certificateTime: val.certificateTime,
            birthday: val.birthday,
            gradeIds: val.gradeIds.join(","),
            photo: val.photo.split("com")[1],
          },
          username: val.phone,
          email: val.email,
          password: val.password,
        };

        this.api.admin.upadetTeacher(data).then((res) => {
          if (res.flag) {
            this.$refs.comdialogTec.isClose();
            this.$notice({
              type: "success",
              message: res.message,
            }).isShow();
            // 判断模块
            this.getSeach(this.gradeId);
          } else {
            this.$notice({
              message: res.message,
            }).isShow();
          }
        });
      }
    },
    onSubmit(val) {
      // 新增
      console.log(val);
      val.name = val.name.trim();
      val.idCard = val.idCard.trim();
      val.parentName = val.parentName.trim();
      val.phone = val.phone.trim();
      //val.gradeId = this.gradeId;
      if (this.alertStatusStu === "add") {
        val.birthday = this.common.dateFormat(val.birthday, "yyyy-MM-dd");
        val.photo = val.photo.split(".com")[1];
        this.api.admin.addStudent(val).then((res) => {
          if (res.flag) {
            // 判断模块
            this.getSeach(this.gradeId);
            this.$notice({
              type: "success",
              message: res.message,
            }).isShow();
          } else {
            this.$notice({
              message: res.message,
            }).isShow();
          }
          this.$refs.comdialogStu.isClose();
        });
      } else if (this.alertStatusStu === "edit") {
        console.log(val);
        val.birthday = this.common.dateFormat(val.birthday, "yyyy-MM-dd");
        val.photo = val.photo.split(".com")[1];
        val.sex = parseInt(val.sex);
        this.api.admin.updateStu(val, val.id).then((res) => {
          if (res.flag) {
            // 判断模块
            this.getSeach(this.gradeId);
            this.$notice({
              type: "success",
              message: res.message,
            }).isShow();
          } else {
            this.$notice({
              message: res.message,
            }).isShow();
          }
          this.$refs.comdialogStu.isClose();
        });
      }
    },
    //打开编辑弹窗
    bindAddClass() {
      this.$refs.comdialogClass.isOpen();
      this.alertStatusClass = "view";
      this.alertStatusClass = "编辑班级";

      this.classInfo = {
        name: "",
        type: "",
      };
    },
    // 编辑班级
    onSubmitClass(val) {
      console.log(val);
    },
    //关闭编辑弹窗
    comdialogCloseClass() {
      this.classInfo = {
        name: "",
        type: "",
      };
    },
    //添加教师打开编辑弹窗
    addTeacher() {
      this.$refs.comdialogTec.isOpen();
      this.alertStatusTec = "add";
    },
    //打开学生编辑弹窗
    addStu() {
      this.$refs.comdialogStu.isOpen();
      this.alertStatusStu = "add";
    },
    // 分页
    changeUpadtePage(val) {
      this.updateParams.page = val;
      // 判断模块
      this.getSeach(this.gradeId);
    },
    // v2.0 搜索
    getSeach(val) {
      let data = {
        name: val,
        schoolId: this.$store.state.userInfo.schoolId,
      };
      this.api.school.searchName(this.updateParams, data).then((res) => {
        console.log(res);
        this.planCount = res.data.planCount;
        this.studentCount = res.data.studentCount;
        this.teacherCount = res.data.teacherCount;
        this.stuData = res.data.studentPages.list;
        this.tecData = res.data.atteTeacherList;
        this.updateParams.total = res.data.studentPages.total;
      });
    },
  },
  watch: {
    banName: {
      handler(newValue) {
        this.banName = newValue;
      },
      immeidate: true,
      deep: true,
    },
    gradeId: {
      handler(newValue) {
        console.log(newValue, "newValue");
        this.getSeach(newValue);
      },
      immeidate: true,
      deep: true,
    },
  },
  components: {
    comdialog,
    comPagination,
    Teacomdialog,
    riseDialog,
  },
};
</script>
<style lang="scss" scoped>
.class-box {
  background: #ffffff;
  box-shadow: 0px 2px 12px 0px rgba(189, 189, 189, 0.41);
  border-radius: 2px;
  padding: 0 30px;
}
.class-content {
  display: flex;
  justify-content: space-between;
  .class-content-left {
    width: 245px;
    box-shadow: 0px 2px 12px 0px rgba(189, 189, 189, 0.41);
    border-radius: 2px;
    .class-left-head {
      width: 245px;
      height: 64px;
      background: #ba3037;
      border-radius: 4px 4px 0px 0px;
      font-size: 18px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #ffffff;
      text-align: center;
      line-height: 64px;
      i {
        margin-right: 10px;
      }
    }
    .children-item-l,
    /deep/ .el-tree-node__content {
      display: flex;
      width: 245px;
      height: 48px;
      justify-content: flex-end;
      align-items: center;
      border-top: 1px solid #f1f1f1;
      padding-left: 0 !important;
      font-weight: bold;
    }
    /deep/ .el-tree-node__label {
      margin-right: 20px;
      font-size: 18px;
      color: #33333d;
    }
    /deep/ .el-tree-node__children .el-tree-node__label {
      font-size: 18px;
      font-family: Adobe Heiti Std;
      color: #33333d;
      font-weight: normal;
    }

    .tree-node-content {
      font-size: 18px;
      font-family: Adobe Heiti Std;
      font-weight: bold;
      color: #33333d;
    }
  }

  .class-content-right {
    width: 1086px;
    background: #ffffff;
    .class-right-head-input {
      width: 100%;
      height: 56px;
      align-items: center;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #ba3037;
      padding-bottom: 10px;
      padding-top: 20px;
    }
    .input-fen-left {
      width: 140px;
      /deep/ .el-input__inner::-webkit-input-placeholder {
        color: #888;
        font-size: 18px;
      }
      /deep/ .el-input__inner::-moz-input-placeholder {
        color: #888;
        font-size: 18px;
      }
      /deep/ .el-input__inner::-ms-input-placeholder {
        color: #888;
        font-size: 18px;
      }
    }
    .class-right-head-input-fen {
      display: flex;
      height: 38px;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 20px;
    }
    .class-right-head-input-select {
      display: flex;
      align-items: center;
      .select-list {
        width: 200px;
      }
    }
    .input-fen-right {
      width: 300px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #ba3037;
      border-radius: 4px;
      padding: 0 20px;
      font-size: 18px;
      input {
        border: none;
        color: #ba3037;
      }
      input::-webkit-input-placeholder {
        color: #888;
      }
      input::-moz-input-placeholder {
        color: #888;
      }
      input::-ms-input-placeholder {
        color: #888;
      }
      span {
        border-left: 1px solid #ba3037;
        padding-left: 20px;
        color: #ba3037;
        cursor: pointer;
      }
    }
    .class-right-head-input-btn {
      width: 112px;
      height: 56px;
      background: url(../../../../assets/img/school/Btn_Bg_U.png) no-repeat 100%
        100%;
      background-size: cover;
      font-size: 18px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #ffffff;
      text-align: center;
      line-height: 56px;
      cursor: pointer;
    }
    .class-right-head-input-btn:hover {
      background: url(../../../../assets/img/school/Btn_Bg_H.png) no-repeat 100%
        100%;
      background-size: cover;
    }
    .class-right-int {
      width: 100%;
      display: flex;
      font-size: 18px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #888888;
      margin-top: 10px;
      padding-bottom: 20px;
      border-bottom: 1px solid #ba3037;
      p {
        margin-right: 40px;
        span {
          color: #333;
        }
      }
    }
  }
}
.class-stu {
  .class-stu-chex {
    display: flex;
    align-items: center;
    margin-top: 12px;
    h2 {
      font-size: 18px;
      font-family: Adobe Heiti Std;
      font-weight: bold;
      color: #333333;
      margin-right: 5px;
    }
    img {
      width: 28px;
      height: 30px;
      cursor: pointer;
    }
  }
  .class-sut-con {
    margin-top: 5px;
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        width: 148px;
        height: 60px;
        margin-right: 21px;
        position: relative;
        .class-sut-con-item {
          font-size: 14px;
          height: 41px;
          margin-top: 10px;
          margin-left: 58px;
          .stu-name {
            color: #333333;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .stu-class {
            color: #888888;
          }
        }
        input[type="checkbox"] {
          position: absolute;
          top: 6px;
          left: 5px;
          width: 14px;
          height: 14px;
          border-radius: 50%;
          background-color: none;
          zoom: 1;
          outline: none;
          -webkit-appearance: none; /*去除默认样式*/
          -webkit-appearance: none;
          -moz-appearance: none;
          -o-appearance: none;
          -ms-appearance: none;
          background: url("../../../../assets/img/school/Icon_UnSel.png")
            no-repeat center center;
        }
        input[type="checkbox"]:checked {
          background: url("../../../../assets/img/school/Icon_Stu_Sel.png")
            no-repeat center center;
        }
      }
      .stu-class-bg {
        background: url(../../../../assets/img/school/Icon_Stu_UnPK.png)
          no-repeat;
        background-size: 100% 100%;
      }
      .stu-class-null-bg {
        background: url(../../../../assets/img/school/Icon_Stu_YPK.png)
          no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}

.class-tec-con {
  margin-top: 11px;
  ul {
    display: flex;
    flex-wrap: wrap;
    li {
      width: 100px;
      text-align: center;
      position: relative;
      img {
        width: 42px;
        height: 42px;
        border-radius: 42px;
      }
      .tec-name {
        font-size: 14px;
        font-family: Adobe Heiti Std;
        font-weight: normal;
        color: #333333;
      }
      input[type="checkbox"] {
        position: absolute;
        top: 6px;
        left: 5px;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background-color: none;
        zoom: 1;
        outline: none;
        -webkit-appearance: none; /*去除默认样式*/
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        -ms-appearance: none;
        background: url("../../../../assets/img/school/Icon_UnSel.png")
          no-repeat center center;
      }
      input[type="checkbox"]:checked {
        background: url("../../../../assets/img/school/Icon_Stu_Sel.png")
          no-repeat center center;
      }
    }
  }
}
.class-stu-btn {
  display: flex;
  cursor: pointer;
  .class-stu-bind {
    width: 112px;
    height: 56px;
    background: url(../../../../assets/img/school/Btn_Bg_Sure.png) no-repeat;
    background-size: 100% 100%;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    line-height: 56px;
  }
  .class-stu-cane {
    width: 100px;
    height: 38px;
    border: 1px solid #ba3037;
    border-radius: 6px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ba3037;
    text-align: center;
    line-height: 38px;
    margin-top: 8px;
    margin-left: 24px;
  }
}
.class-left-list
  /deep/
  .el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background-color: none;
  border-left: 3px solid #ba3037;
}
.class-left-list
  /deep/
  .el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content
  .el-tree-node__label {
  color: #ba3037 !important;
}
.icon-class-add {
  margin-right: 15px;
}

input-checkbox[type="checkbox"] {
  position: absolute;
  top: 0;
  right: 0;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: none;
  zoom: 1;
  outline: none;
  -webkit-appearance: none; /*去除默认样式*/
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
  background: url("../../../../assets/img/school/Icon_UnSel.png") no-repeat
    center center;
}
input-checkbox[type="checkbox"]:checked {
  background: url("../../../../assets/img/school/Icon_Stu_Sel.png") no-repeat
    center center;
}

/deep/ .el-dialog {
  background: #ffffff;
  border-radius: 10px;
  .el-dialog__body {
    padding: 20px 0;
  }
  .layui-table,
  .layui-table-view {
    margin: 0;
  }
  .el-dialog__header {
    padding: 0;
    width: 100%;
    height: 50px;
    background: #b93138;
    text-align: center;
    line-height: 50px;
    border-radius: 10px 10px 0 0;
    span {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
    }
    .el-dialog__headerbtn .el-dialog__close {
      color: #fff;
    }
  }
  .el-dialog-con {
    display: flex;
    justify-content: space-between;
    .el-dialog-left {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        margin: 30px 0 24px 0;
      }
    }
    .title {
      font-size: 16px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }
  }
  .dowm-btn {
    display: block;
    width: 152px;
    height: 56px;
    background: url(../../../../assets/img/school/Btn_Bg_DownL_U.png) no-repeat;
    background-size: cover;
    text-align: center;
    line-height: 56px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    margin-top: 12px;
  }
  .upload-demo {
    width: 152px;
    height: 56px;
    background: url(../../../../assets/img/school/Btn_Bg_UpD_U.png) no-repeat;
    background-size: cover;
    text-align: center;
    line-height: 56px;
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    margin-top: 12px;
  }
  .dowm-btn:hover {
    background: url(../../../../assets/img/school/Btn_Bg_DownL_H.png) no-repeat;
    background-size: cover;
  }
  .upload-demo:hover {
    background: url(../../../../assets/img/school/Btn_Bg_UpD_H.png) no-repeat;
    background-size: cover;
  }
}
.menu-item {
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #33333d;
  height: 48px;
  font-size: 18px;
  line-height: 48px;
  text-align: right;
  border-top: 1px solid #f1f1f1;
  padding: 0 10px;
}
.page {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dialog_title {
  display: block;
  width: 100%;
  height: 0.9rem /* 108/120 */;
  line-height: 0.9rem;
  background: #bb3037;
  text-align: center;
  font-size: 0.4rem /* 48/120 */;
  font-weight: 500;
  color: #ffffff;
  padding: 0;
  border-radius: 0px 60px 0px 0px;
  font-family: Alibaba PuHuiTi;
}
.dialog_table_th {
  position: relative;
  height: 0.75rem /* 90/120 */;
  line-height: 0.75rem /* 90/120 */;
  background: #f8f5f6;
  font-family: Alibaba PuHuiTi;
}
.dialog_table_th_sl {
  position: absolute;
  // display:-moz-inline-box;
  display: inline-block;
  right: 0.03rem;
  width: 2px /*no*/;
  height: 0.32rem;
  background: #bb3037;
}
table {
  border-collapse: collapse;
  width: 100%;
}
tbody {
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  height: auto;
}
thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  word-break: break-all;
}
.class_home_page {
  .config {
    width: 200px;
    height: 20px;
    line-height: 20px;
    background: #228cec;
    color: #fff !important;
    font-size: 20px;
    font-weight: bold;
    padding: 18px 40px;
    cursor: pointer;
    text-align: center;
  }

  .btns {
    margin-top: 50px;
    @include flex(row, flex-start, center);

    .config_submit,
    .config_cancal {
      width: 80px;
      height: 20px;
      line-height: 20px;
      background: #228cec;
      color: #fff !important;
      font-size: 20px;
      font-weight: bold;
      padding: 18px 40px;
      cursor: pointer;
      margin-right: 16px;
      text-align: center;
    }
  }

  .table_top {
    @include flex(row, space-between, center);

    .title {
      font-size: 34px;
      font-family: Alibaba PuHuiTi;
      font-weight: bold;
      color: #cc1a30;
      //   margin-bottom: 30px;
    }
  }

  .top_block1 {
    flex: 1;
    @include flex(row, space-between, center);

    .title {
      font-size: 18px;
      font-weight: 400;
      color: black;
      margin-bottom: 0;
    }
  }

  .top_block2 {
    flex: 1;
    @include flex(row, flex-end, center);

    .table_top_btn_default {
      margin-left: 10px;
    }
  }

  .handleStuAlertShow {
    @include flex(column, felx-start, flex-start);

    .title {
      font-size: 0.2rem;
      line-height: 0.35rem;
      margin: 20px 0px 10px;
    }

    .btn {
      background: #228cec;
      color: #fff;
      height: 0.4rem;
      line-height: 0.4rem;
      border-radius: 0.25rem;
      font-size: 0.2rem;
      text-align: center;
      display: block;
    }
  }
  /deep/ .batch_result .el-dialog {
    background: transparent;
  }
  /deep/ .batch_result .el-dialog__body,
  /deep/ .batch_result .el-dialog__header {
    padding: 0;
  }
  /deep/ .batch_result .el-dialog__headerbtn {
    top: 36px;
    right: 44px;
  }
  /deep/ .batch_result .el-dialog__headerbtn .el-dialog__close {
    font-size: 32px;
    color: #ffffff;
  }
  /deep/ .batch_result .el-input__inner {
    border: none;
    height: 0.341667rem /* 41/120 */;
  }
  /deep/ .batch_result .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #3a3c42;
    height: 0.541667rem /* 65/120 */;
    // vertical-align: bottom;
  }
  /deep/ .batch_result .layui-table td,
  .layui-table th {
    padding: 0.075rem 0.166667rem /* 20/120 */;
  }

  .dialog_title {
    display: block;
    width: 100%;
    height: 0.9rem /* 108/120 */;
    line-height: 0.9rem;
    background: #bb3037;
    text-align: center;
    font-size: 0.4rem /* 48/120 */;
    font-weight: 500;
    color: #ffffff;
    padding: 0;
    border-radius: 0px 60px 0px 0px;
    font-family: Alibaba PuHuiTi;
  }
  .dialog_table_th {
    position: relative;
    height: 0.75rem /* 90/120 */;
    line-height: 0.75rem /* 90/120 */;
    background: #f8f5f6;
    font-family: Alibaba PuHuiTi;
  }
  .dialog_table_th_sl {
    position: absolute;
    // display:-moz-inline-box;
    display: inline-block;
    right: 0.03rem;
    width: 2px /*no*/;
    height: 0.32rem;
    background: #bb3037;
  }
  .custTab {
    margin-top: -15px;
  }
  .custTab thead th,
  .custTab tbody td {
    text-align: center;
    font-size: 0.283333rem /* 34/120 */;
    font-weight: 500;
    font-family: Alibaba PuHuiTi;
  }
  .dialog_table_tbody {
    padding-top: 0.083333rem /* 10/120 */;
  }
  .dialog_table_tbody .err_tip {
    position: absolute;
    top: 0.51667rem;
    display: inline-block;
    margin-left: 0.9rem;
    padding: 0 0.133333rem /* 16/120 */;
    height: 0.233333rem /* 28/120 */;
    line-height: 0.233333rem /* 28/120 */;
    background: #bb3037;
    color: #ffffff;
    border-radius: 0 0 5px 5px;
    font-size: 0.116667rem /* 14/120 */;
    div {
      display: flex;
      align-items: center;
    }
    span {
      padding-left: 5px;
    }
  }
  .err_tip.name {
    margin-left: 2.03rem;
  }
  .err_tip.sex {
    margin-left: 4.52rem;
  }
  .err_tip.brithDay {
    margin-left: 5.65rem;
  }
  .err_tip.idCard {
    margin-left: 7.63rem;
  }
  .err_tip.parentName {
    margin-left: 9.96rem;
  }
  .err_tip.phone {
    margin-left: 12.23rem;
  }
  .dialog_table_tr {
    // margin-bottom: .3125rem;
    height: 0.516667rem /* 62/120 */;
    line-height: 0.516667rem /* 62/120 */;
    background: #f8f5f6;
  }
  .inpsty {
    align-items: center;
    min-width: 0.8rem;
    width: 100%;
    height: 0.341667rem /* 41/120 */;
    // font-size: .2rem /* 24/120 */;
    font-size: 0.14rem;
    text-align: center;
    display: flex;
    justify-content: center;
    resize: none;
    outline: none;
    border: 1px solid #bb3037;
    border-radius: 5px;
  }
  .el-textarea {
    text-align: center;
  }
  .dialog_table_th_icon {
    width: 0.4rem;

    img {
      margin-top: -0.04rem;
    }
  }
  .dialog_table_th_num {
    width: 0.8rem;
  }
  .dialog_table_th_sex {
    width: 0.8rem;
  }
  .dialog_table_th_birth {
    width: 1.6rem;
  }
  .dialog_table_th_cardId {
    width: 2rem;
  }
  .dialog_table_th_phone {
    width: 1.6rem;
  }
  .btn_container {
    text-align: center;
  }

  .dialog_table_tbody {
    &::-webkit-scrollbar {
      width: 0.116667rem /* 14/120 */;
    }
    &::-webkit-scrollbar-button {
      display: none;
    }
    &::-webkit-scrollbar-track {
      background: #eeeeee;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #bb3037;
      border-radius: 0.05rem /* 6/120 */;
    }
  }
}
.btn_sty {
  height: 0.558333rem /* 67/120 */;
  padding: 0 0.316667rem /* 38/120 */;
  background: #bb3037;
  border-radius: 5px;
  font-size: 0.283333rem /* 34/120 */;
  font-weight: 500;
  color: #ffffff;
  font-family: Alibaba PuHuiTi;
}
.dialog_table_tr {
  // margin-bottom: .3125rem;
  height: 0.516667rem /* 62/120 */;
  line-height: 0.516667rem /* 62/120 */;
  background: #f8f5f6;
}
.inpsty {
  align-items: center;
  min-width: 0.8rem;
  width: 100%;
  height: 0.341667rem /* 41/120 */;
  // font-size: .2rem /* 24/120 */;
  font-size: 0.14rem;
  text-align: center;
  display: flex;
  justify-content: center;
  resize: none;
  outline: none;
  border: 1px solid #bb3037;
  border-radius: 5px;
}
.el-textarea {
  text-align: center;
}
.dialog_table_th_icon {
  width: 0.4rem;

  img {
    margin-top: -0.04rem;
  }
}
.dialog_table_th_num {
  width: 0.8rem;
}
.dialog_table_th_sex {
  width: 0.8rem;
}
.dialog_table_th_birth {
  width: 1.6rem;
}
.dialog_table_th_cardId {
  width: 2rem;
}
.dialog_table_th_phone {
  width: 1.6rem;
}
.btn_container {
  text-align: center;
}
.btn_sty {
  height: 0.558333rem /* 67/120 */;
  padding: 0 0.316667rem /* 38/120 */;
  background: #bb3037;
  border-radius: 5px;
  font-size: 0.283333rem /* 34/120 */;
  font-weight: 500;
  color: #ffffff;
  font-family: Alibaba PuHuiTi;
}
.dialog_table_tbody {
  &::-webkit-scrollbar {
    width: 0.116667rem /* 14/120 */;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-track {
    background: #eeeeee;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #bb3037;
    border-radius: 0.05rem /* 6/120 */;
  }
}
.dialog_table_tbody .err_tip {
  position: absolute;
  top: 0.51667rem;
  display: inline-block;
  margin-left: 0.9rem;
  padding: 0px 0.13333rem;
  height: 0.23333rem;
  line-height: 0.23333rem;
  background: rgb(187, 48, 55);
  color: rgb(255, 255, 255);
  border-radius: 0px 0px 0.05rem 0.05rem;
  font-size: 0.11667rem;
}
/deep/ .batch_result .el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
  background: #3a3c42;
  height: 0.541667rem /* 65/120 */;
  // vertical-align: bottom;
}

.recover-btn {
  width: 112px;
  height: 56px;
  background: url(../../../../assets/img/school/Btn_Bg_U.png) no-repeat 100%
    100%;
  background-size: cover;
  font-size: 18px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #ffffff;
  text-align: center;
  line-height: 56px;
  cursor: pointer;
}
.recover-btn :hover {
  background: url(../../../../assets/img/school/Btn_Bg_H.png) no-repeat 100%
    100%;
  background-size: cover;
}
.tab {
  img {
    cursor: default;
  }
}
.class-right-head-input-title {
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ba3037;
}
.class_go_up_btn {
  width: 136px;
  height: 56px;
  background: url(../../../../assets/img/school/Btn_SB_Bg_U.png) no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #ffffff;
  cursor: pointer;
  img {
    width: 19px;
    height: 18px;
    margin-right: 9px;
  }
}
/deep/ .el-button--primary {
  width: 100px;
  background: linear-gradient(232deg, #cb1930, #ff4054);
  box-shadow: 0px 3px 6px 0px rgba(255, 34, 67, 0.33);
  border-radius: 6px;
  border: none;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  margin-right: 20px;
}
/deep/ .el-button--default {
  width: 100px;
  border: 1px solid #cc1930;
  border-radius: 6px;
  background: none;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #cc1930;
}
/deep/ .el-button--primary:hover {
  background: linear-gradient(232deg, #cb1930, #f13131);
}
/deep/ .el-button--default:hover {
  border: 1px solid #f13131;
  color: #f13131;
}
</style>