<template>
  <div class="attron">
    <el-dialog :visible.sync="visible" center @click="isClose">
      <template slot="title">
        <div class="upWT">学年升班</div>
        <div class="close"></div>
      </template>
      <div class="riseConter">
        <p>
          1、自动升学功能将帮助您将园所所有的小班升为中班，中班升为大班，大班升为待毕业。
        </p>
        <p>
          2.待毕业的学生在一个月以后系统会自动毕业，老师也可以手f动选择毕业。在此期间有需要留级的学员老师进行分班转入新的班级。
        </p>
        <p>3.在所有班级升班之后，您需要在新学期重新新建小班。</p>

        <p class="hint">提示：在点击确定按钮后操作不可撤销，请您谨慎操作。</p>
      </div>
      <div style="text-align: center; margin: 0.2rem auto 0 auto">
        <el-button class="btn active" @click="getQue">确定</el-button>
        <el-button class="btn" @click="isClose">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "rise",
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    isClose() {
      this.visible = false;
    },
    getQue() {
      this.$emit("onSubmit");
    },
    isOpen() {
      this.visible = true;
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.riseConter {
  padding: 30px;
  p {
    color: #333;
    line-height: 45px;
  }
}
.hint {
  color: red !important;
}

.hintMailbox {
  font-size: 14px;
  font-weight: 400;
  color: #cb1b32;
  text-align: center;
}

.btn {
  width: 100px;
  height: 38px;
  //background: none;
  //border:none;
  border: 1px solid #ba3037;
  border-radius: 6px;
  background: #fff;
  color: #ba3037;
  font-size: 18px;
  margin: 0 25px;
}

.active {
  background: linear-gradient(232deg, #b93037, #ef525b);
  box-shadow: 0 3px 6px 0 rgba(255, 67, 77, 0.33);
  color: #fff;
  border: 1px solid transparent;
}

/deep/ .el-dialog__header {
  padding: 0;
  position: relative;
}

/deep/ .el-dialog__headerbtn {
  top: 0.15rem;
  opacity: 0;
}

.upWT {
  height: 50px;
  line-height: 50px;
  background: #b93138;
  color: #fff;
}

.close {
  position: absolute;
  top: 16px;
  right: 20px;
  width: 15px;
  height: 15px;
  background: url(../../assets/img/course/Icon_tc_close.png) no-repeat;
  background-size: cover;
}

/deep/ .el-input__suffix {
  line-height: 0.5rem;
}
</style>